<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col
      cols="12"
    >
      <v-row
        class="statistics-box pb-4"
        :class="{'blocked-box' : blocked_visibility}"
      >
        <v-col
          cols="12"
          class="d-flex align-center "
        >
          <span class="font-weight-bold"> 14 Декларант
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item>
                  Поиск в справочнике
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
        <v-col
          cols="1"
          class="pr-0"
        >
          <label>
            Страна
            <v-autocomplete
              ref="country_letter"
              v-model="declarant_json.country_letter"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="letterCode"
              item-value="letterCode"
              :filter="filterBySearchField"
            />
          </label>
        </v-col>
        <v-col
          cols="3"
          class="pr-0"
        >
          <label>
            <!-- identityDocumentsFiltered -->
            Код вида док.
            <v-autocomplete
              ref="identity_doc_name"
              v-model="declarant_json.identity_doc_name"
              :items="identityDocumentsFiltered"
              :filter="filterBySearchField"
              item-text="search"
              item-value="name"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Номер документа
            <v-text-field
              ref="declarant_identity_doc_number"
              v-model="declarant.declarant_identity_doc_number"
              v-mask="'SS#######'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              @input="(v) => (declarant.declarant_identity_doc_number = v.toLocaleUpperCase())"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Дата документа
            <custom-date-picker
              ref="identity_doc_date_on"
              v-model="declarant_json.identity_doc_date_on"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              dense
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Дата истечения срока
            <custom-date-picker
              ref="identity_doc_date_off"
              v-model="declarant_json.identity_doc_date_off"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              dense
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Личный номер
            <v-text-field
              ref="personal_number"
              v-model="declarant_json.personal_number"
              v-mask="'#######S###SS#'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              @input="(v) => (declarant_json.personal_number = v.toLocaleUpperCase())"
              @change="checkIsForbiddenPerson"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Фамилия
            <v-text-field
              ref="declarant_last_name"
              v-model="declarant.declarant_last_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Имя
            <v-text-field
              ref="declarant_first_name"
              v-model="declarant.declarant_first_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Отчество
            <v-text-field
              v-model="declarant.declarant_middle_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            День рождения
            <custom-date-picker
              v-model="declarant.declarant_date_birth"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              dense
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Контактный телефон
            <v-text-field
              v-model="declarant.declarant_phone"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="12"
        >
          <div class="d-flex">
            <label class="mr-4">
              Индекс
              <v-text-field
                v-model="declarant_json.postal_index"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
            <label
              class="mr-4"
              style="width: 360px;"
            >
              Область/Район
              <v-text-field
                v-model="declarant_json.region"
                :error="declarant_json.region?.length > 50"
                maxlength="50"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
            <label
              class="mr-4"
              style="width: 280px;"
            >
              Город/Деревня
              <v-text-field
                ref="city"
                v-model="declarant_json.city"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
            <label
              class="mr-4"
              style="width: 400px;"
            >
              Улица
              <v-text-field
                ref="street_house"
                v-model="declarant_json.street_house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
            <label
              class="mr-4"
              style="width: 120px;"
            >
              Дом
              <v-text-field
                ref="house"
                v-model="declarant_json.house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
            <label
              class="mr-0"
              style="width: 160px;"
            >
              Номер помещения
              <v-text-field
                v-model="declarant_json.room"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getCountryNameByCode, getIdentityCodeByName} from "@/helpers/catalogs";
import formatDate from '@/mixins/format-date';
import {highlightField} from '@/helpers/control'
import {declarant as onDeclarant} from '@/events/statistics/control'
import {eventBus} from "@/bus.js";
import blockVisibility from '@/mixins/block-visibility'
import {checkIsForbidden} from "@/helpers/risks";

export default {
  components: {
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, formatDate, filterBySearchFieldMixin, blockVisibility],
  data: () => ({
    block_id: 'rs-declarant-physical',
    promises: [],
    declarant: {
      declaration_id: null,
      declarant_branch_name: '',
      declarant_branch_unp: '',
      declarant_first_name: '',
      declarant_identity_doc_number: '',
      declarant_last_name: '',
      declarant_middle_name: '',
      declarant_name: '',
      declarant_unp: '',
      declarant_date_birth: '',
      declarant_phone: ''
    },
    declarant_json: {
      country_name: '',
      country_letter: '',
      personal_number: '',
      postal_index: '',
      region: '',
      city: '',
      street_house: '',
      house: '',
      room: '',
      aeo_country_letter: '',
      aeo_country_name: '',
      aeo_reg_number: '',
      branch_city: '',
      branch_country_letter: '',
      branch_country_name: '',
      branch_house: '',
      branch_postal_index: '',
      branch_region: '',
      branch_room: '',
      branch_street_house: '',
      identity_doc_date_off: '',
      identity_doc_date_on: '',
      identity_doc_code: '',
      identity_doc_name: ''
    }
  }),
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      countries: "catalogs/getNsiCountries",
      identityDocuments: 'catalogs/getNsiIdentityDocuments',
      user: "auth/getUser"
    }),
    identityDocumentsFiltered() {
      if (this.declarant_json.country_letter) {
        if (['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.declarant_json.country_letter)) {
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.declarant_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    }
  },
  created() {
    onDeclarant.subscribe(this.highlightField)
    eventBus.$on("clearRsDeclarantPhysical", () => {
      this.clearFieldsOnTypeChange()
    });
    this.$store.dispatch('auth/loadSingleDivisions', this.user.user.activeDivisionId)
  },
  methods: {
    highlightField,
    checkIsForbiddenPerson(identity) {
      checkIsForbidden.call(this, {
        identity,
        isPerson: true,
        promises: this.promises,
        dataModel: "declarant_json.personal_number",
      })
    },
    clearFieldsOnTypeChange() {
      const declarant_physical = this.convertEmptyStringsToNull({
        ...this.declarant,
        declarant_unp: null,
        declarant_name: null,
        declarant_branch_unp: null,
        declarant_branch_name: null,
        declarant_identity_doc_number: null,
        declarant_last_name: null,
        declarant_first_name: null,
        declarant_middle_name: null,
        declarant_date_birth: null,
        declarant_phone: null,
        declarant_json: {
          ...this.declarant_json,
          country_name: null,
          country_letter: null,
          aeo_reg_number: null,
          aeo_country_letter: null,
          aeo_country_name: null,
          branch_country_letter: null,
          branch_country_name: null,
          branch_street_house: null,
          branch_house: null,
          branch_room: null,
          branch_postal_index: null,
          postal_index: null,
          branch_region: null,
          branch_city: null,
          identity_doc_code: null,
          identity_doc_name: null,
          identity_doc_date_on: null,
          identity_doc_date_off: null,
          personal_number: null,
          region: null,
          city: null,
          street_house: null,
          house: null,
          room: null
        }
      });
      return this.$store.dispatch("rs/uploadBlockData", {
        name: "declarant",
        value: declarant_physical,
      });
    },
    setFields() {
      const declarant = this.selected.declarant
      const declarant_json = this.selected.declarant.declarant_json

      Object.keys(this.declarant).forEach(key => {
        if (key in declarant) {
          this.declarant[key] = declarant[key]
        }
      })

      Object.keys(this.declarant_json).forEach(key => {
        if (key in declarant_json) {
          this.declarant_json[key] = declarant_json[key]
        }
      })
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        const declarant_physical = this.convertEmptyStringsToNull({
          ...this.declarant,
          declarant_unp: null,
          declarant_name: null,
          declarant_branch_unp: null,
          declarant_branch_name: null,
          declarant_json: {
            ...this.declarant_json,
            country_name: getCountryNameByCode(this.countries, this.declarant_json.country_letter) || null,
            aeo_reg_number: null,
            aeo_country_letter: null,
            aeo_country_name: null,
            branch_country_letter: null,
            branch_country_name: null,
            branch_street_house: null,
            branch_house: null,
            branch_room: null,
            branch_postal_index: null,
            branch_region: null,
            branch_city: null,
            identity_doc_code: getIdentityCodeByName(this.identityDocuments, this.declarant_json.identity_doc_name) || null
          }
        });

        if (this.createDocForPassport()) {
          const passport_data = {
            docNumber: this.declarant.declarant_identity_doc_number,
            docDate: this.formatDate(this.declarant_json.identity_doc_date_on)
          }
          this.$store.dispatch("rs/createDocForPassport", passport_data)
        }

        return this.$store.dispatch("rs/uploadBlockData", {
          name: "declarant",
          value: declarant_physical,
        });
      })
    },
    createDocForPassport() {
      if (this.selected.declarant.declarant_identity_doc_number === null &&
          this.declarant.declarant_identity_doc_number !== '' &&
          this.selected.declarant.declarant_json.identity_doc_date_on === null &&
          this.declarant_json.identity_doc_date_on !== ''
      ) {
        const pasportInDocs = this.selected.presented_documents.find(doc => doc.doc_code === '10021')
        if (pasportInDocs) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  }
}
</script>
