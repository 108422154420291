<template>
  <div
    v-click-outside="checkOrderAndUpdate"
  >
    <v-container
      fluid
    >
      <div
        class="statistics-box position-relative rs-documents-list"
      >
        <v-col cols="12">
          <v-row>
            <v-col
              cols="2"
              class="pb-0"
            >
              <span class="font-weight-bold">
                22 Документы ({{ presented_documents.length }})
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      dense
                      class="mx-1"
                      v-on="on"
                    >mdi-tune</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      :disabled="disabledView ? true: !selectedDocs.length"
                      @click="deleteDocumentArr"
                    >
                      Удалить выбранные документы
                    </v-list-item>
                    <v-list-item
                      :disabled="disabledView ? true: !selectedDocs.length"
                      @click="clearRangeForSelected"
                    >
                      Очистить поле "для товаров" у выбранных
                    </v-list-item>
                    <v-list-item
                      :disabled="disabledView ? true: !selectedDocs.length"
                      @click="hideTooltips = !hideTooltips"
                    >
                      {{ hideTooltips ? 'Включить' : 'Отключить' }} подсказки
                    </v-list-item>
                    <v-list-item
                      :disabled="disabledView ? !selectedDocs.length : true"
                      @click="attachFiles"
                    >
                      Прикрепить файлы
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
            </v-col>
            <v-col
              cols="5"
              class="pb-0"
              style="z-index:3;"
            >
              <v-checkbox
                v-model="showAllDocs"
                tabindex="-1"
                dense
                color="#5CB7B1"
              >
                <template #label>
                  <label
                    class="ma-0"
                    style="font-size: 12px"
                  >Показать все документы</label>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="5"
              class="d-flex justify-end"
            >
              <v-row class="align-center justify-end px-3">
                <span
                  class="mr-2"
                  style="font-size: 12px"
                >
                  Для всех товаров
                </span>
                <v-switch
                  v-model="forEditedGoods"
                  tabindex="-1"
                  hide-details="auto"
                  dense
                  color="#5CB7B1"
                >
                  <template #label>
                    <label
                      style="font-size: 12px"
                    >Для редактируемого товара</label>
                  </template>
                </v-switch>
              </v-row>

              <!-- <v-btn
                tabindex="-1"
                min-width="36px"
                max-width="36px"
                height="36px"
                color="#5CB7B1"
                class="ml-3 px-0"
                @click="togglePin"
              >
                <v-icon>{{ pinned.includes(pinName) ? "mdi-pin-off" : "mdi-pin" }}</v-icon>
              </v-btn> -->

              <v-btn
                tabindex="-1"
                min-height="0px"
                text
                min-width="32px"
                class="ml-3 pa-3"
                @click="collapsed = !collapsed"
              >
                <v-icon>
                  {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-show="!collapsed"
              cols="12"
              :class="{'py-10' : !presented_documents.length}"
            >
              <v-btn
                v-if="!presented_documents.length"

                class="document-add elevation-0 px-15"
                color="transparent"
                @click="addNewDocument"
              >
                Добавить <v-icon>mdi-plus</v-icon>
              </v-btn>
              <div
                v-if="presented_documents.length"
                class="d-flex px-1"
              >
                <div class="header__select">
                  <v-checkbox
                    tabindex="-1"
                    dense
                    hide-details="true"
                    color="#5CB7B1"
                    :value="isAllSelected"
                    @change="checkAll"
                  />
                </div>
                <div class="header__sort">
                  <small>№ п/п</small>
                </div>
                <div class="header__code">
                  <small>Код</small>
                </div>
                <div
                  class="header__sign"
                >
                  <small>Признак представл. док.</small>
                </div>
                <div
                  class="header__number"
                >
                  <small>Номер документа</small>
                </div>
                <div class="header__date">
                  <small>Дата</small>
                </div>
                <div class="header__range">
                  <small>Для товаров</small>
                </div>
                <div
                  v-if="disabledView"
                  class="header__bd44"
                >
                  <small>БД44</small>
                </div>
              </div>
              <v-virtual-scroll
                bench="10"
                item-height="50px"
                :items="presented_documents"
                class="drop-zone"
                @drop="onDrop($event, 1)"
                @dragover.prevent
                @dragenter.prevent
              >
                <template #default="{index,item}">
                  <single-document
                    :ref="'doc_' + index"
                    :key="'doc_' + index"
                    :item="item"
                    :index="index"
                    :for-single-item="forEditedGoods"
                    :draggable.sync="draggable"
                    :hide-tooltips="hideTooltips"
                    :selected-docs="selectedDocs"
                    :presented-documents="presented_documents"
                    :control="controlPath"
                    @select="onSelect"
                    @delete="deleteDocument"
                    @addNewDoc="addNewDocument"
                  />
                </template>
              </v-virtual-scroll>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-container>
    <component
      :is="activeComponent"
      :ids="selectedDocs"
      :show.sync="show"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SingleDocument from "@/components/rs/documents/single-document.vue";
import { rangeToArray } from "@/helpers/inputs";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import {presented_documents as onPresentedDocuments} from '@/events/statistics/control'
import blockVisibility from "@/mixins/block-visibility";
import highlightFieldsParent from "@/mixins/control/highlight-fields-parent";

export default {
  components: {
    SingleDocument,
  },
  mixins: [blockVisibility, highlightFieldsParent],
  data: () => ({
    // pinName:'documents',
    show:false,
    activeComponent:null,
    selectedDocs: [],
    showAllDocs: false,
    draggable: false,
    hideTooltips: true,
    forEditedGoods: false,
    date: null,
    menu: false,
    pin: false,
    presented_documents: [],
    orderChanged: false,
    collapsed: false,
    controlSettings: {
      controlBlockSelector: ".rs-documents-list",
      controlElementSelector: ".rs-documents-list .v-virtual-scroll",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
      // editableWare: "statistics/getEditableWare",
      pinned:'ui/getPinned',
      disabledView:'rs/getVisibility'
    }),
    collapsedDocs(){
      if(!this.showAllDocs){
        return this.presented_documents.slice(0, 10)
      }else{
        return this.presented_documents
      }
    },
    isAllSelected(){
      return this.selectedDocs.length && this.selectedDocs.length === this.presented_documents.length
    },
    tableHeight(){
      return this.presented_documents.length * 50
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true
    },
    editableWare() {
      this.showDocumentsForSelectedWare();
    },
    forEditedGoods(nv) {
      this.draggable = nv === true ? false : true;
      this.showDocumentsForSelectedWare();
    },
    "selected.presented_documents"() {
      this.showDocumentsForSelectedWare();
    },
  },
  created(){
    onPresentedDocuments.subscribe(this.onControl)
  },
  beforeDestroy(){
    onPresentedDocuments.unsubscribe()
  },
  methods: {
    attachFiles(){
      this.activeComponent = () => import('./attach-files.vue')
      this.show = true
    },
    // togglePin(){
    //   this.$store.commit('ui/TOGGLE_PIN', this.pinName)
    // },
    checkAll(){
      if(this.selectedDocs.length === this.presented_documents.length){
        this.selectedDocs = []
      }else{
        this.selectedDocs = this.presented_documents.map(i => i.id)
      }
    },
    onSelect(id){
      if(this.selectedDocs.includes(id)){
        this.selectedDocs = this.selectedDocs.filter(i => i !== id)
      }else{
        this.selectedDocs.push(id)
      }
    },
    showDocumentsForSelectedWare() {
      if (this.forEditedGoods) {
        const { presented_documents } = this.selected || {};
        const { index } = this.editableWare;
        const idx = index + 1;
        this.presented_documents = presented_documents.filter(
          (doc) =>
            rangeToArray(doc.ware_range).includes(idx) ||
            doc.ware_range === null
        );
      } else {
        this.setFields();
      }
    },
    setFields() {
      this.presented_documents = this.selected?.presented_documents || [];
    },
    addNewDocument() {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.createDocument();
        });
      } else {
        this.createDocument();
      }
    },
    createDocument() {
      this.$store.dispatch("rs/addDocument")
    },
    deleteDocument(payload) {
      if (this.orderChanged) {
        this.checkOrderAndUpdate().then(() => {
          this.$store.dispatch("rs/deleteDocument", payload).catch(() => {
            this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
          })
        });
      } else {
        this.$store.dispatch("rs/deleteDocument", payload).catch(() => {
          this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
        })
      }
    },
    clearRangeForSelected(){
      const documents = this.presented_documents
        .filter(item => this.selectedDocs.includes(item.id))
        .map(item => {
          return {
            ...item,
            ware_range:""
          }
        })
      const payload = convertEmptyStringsToNull(documents)
      this.$store.dispatch("rs/updateDocument", { payload }).catch(() => {
        this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
      })
    },
    deleteDocumentArr(){
      this.$store.dispatch("rs/deleteDocumentArr", this.selectedDocs).catch(() => {
        this.$snackbar({text:"Ошибка", color:"red", top:false, right: false})
      });
      this.unsetSelected()
      this.checkOrderAndUpdate();
    },
    onDrop(evt) {
      const document = evt.dataTransfer.getData("document");
      if(!document) return; // Завершаем если нет ID
      const targetElementId =
        evt.target.parentNode.getAttribute("id") ||
        evt.target.closest("div.document__row").getAttribute("id");
      if (targetElementId) {
        const target_parts = targetElementId.split("-");
        const target_id = target_parts[target_parts.length - 1];

        if(Number.parseInt(target_id) > 0){ // Завершаем если нет ID
          const target_document = this.presented_documents.find((i) => i.id === Number.parseInt(target_id))
          const target_index = this.presented_documents.indexOf(target_document);
          const dragged_document = this.presented_documents.find(
            (i) => i.id === Number.parseInt(document)
          );

          const dragged_index =
          this.presented_documents.indexOf(dragged_document);
          if (target_index !== dragged_index) {
            if (target_index < dragged_index) {
              this.presented_documents.splice(target_index, 0, dragged_document);
              this.presented_documents.splice(dragged_index + 1, 1);

            } else {
              this.presented_documents.splice(dragged_index, 1);
              this.presented_documents.splice(target_index, 0, dragged_document);
            }
            this.orderChanged = true;
          }
          this.checkOrderAndUpdate();

        }

      }
      this.draggable = false;
      this.selectedDocs = []
    },
    checkOrderAndUpdate() {
      if (this.orderChanged) {
        const docs = this.presented_documents.map((document, idx) => {
          return {
            ...document,
            sort_index: idx + 1,
          };
        });
        const payload = docs.map((doc) => convertEmptyStringsToNull(doc));
        return this.$store
          .dispatch("rs/updateDocument", { payload })
          .then(() => {
            this.orderChanged = false;
          }).catch(()=> {
            this.$snackbar({text:"Ошибка при сортировке",color:"red", top:false, right: false});
          });
      }
    },
    unsetSelected(){
      this.selectedDocs = []
    }
  },
};
</script>
<style scoped>
.header__sort{
  width: 60px !important;
}
.header__code{
  width: 180px !important;
  padding: 0 10px 0 0;
}
.header__sign{
  width: 160px !important;
  padding: 0 10px 0 0;
}
.header__number{
  width: 300px !important;
  padding: 0 10px 0 0;
}
.header__date{
  width: 160px !important;
}
.header__range{
  width: 250px !important;
  padding: 0 10px;
}
.header__bd44{
  width: 30px !important;
}


.v-data-table__wrapper {
  padding-bottom: 0 !important;
}

.document-add {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
}


</style>
