import { swipeToPinnedComponent } from "@/events/common";
import {getFirstValueFromMap, getScrollOffset} from "@/helpers/control";

export default {
  data: () => ({
    controlSettings: {
      controlBlockSelector: null,
      controlElementSelector: null,
    },
    controlPath: {
      path: "",
      index: null,
      timestamp: null,
    },
  }),
  methods: {
    onControl(data) {
      if (
        "pinned" in this &&
        "pinName" in this &&
        this.pinned.includes(this.pinName)
      ) {
        swipeToPinnedComponent.trigger({ pin: this.pinName });
      }
      const block = document.querySelector(
        this.controlSettings.controlBlockSelector
      );
      const offset = getScrollOffset();
      this.$scrollTo(block, 200, {
        force: true,
        offset,
        onDone: () => this.highlight(data),
      });
    },
    highlight({path}) {
      const index = getFirstValueFromMap(path)
      const offsetTop = (index - 1) * 50;
      const element = document.querySelector(
        this.controlSettings.controlElementSelector
      );
      element.scrollTop = offsetTop;
      this.controlPath = {
        path,
        index,
        timestamp: Date.now(),
      };
    },
  },
};
