import {getFirstKeyFromMap, highlightField} from "@/helpers/control";

const TIMEOUT = 500;

export default {
  props: {
    control: {
      required: true,
      validator:(v) => typeof v === 'object' || v === null
    },
  },
  data(){
    return {
      isMountedTickComplete:false,
      runOnNextUpdateHook:false,
      highlightFieldTimer:null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isMountedTickComplete = true
    })
  },
  updated(){
    if(this.runOnNextUpdateHook){
      clearTimeout(this.highlightFieldTimer)
      this._highlight()
      this.runOnNextUpdateHook = false
    }
  },
  watch: {
    control: {
      handler({path}) {
        if(!path) return;
        if(this.isMountedTickComplete){
          this._highlight()
        }else {
          this.runOnNextUpdateHook = true
          this._runIfUpdateSkipped()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods:{
    _runIfUpdateSkipped(){
      this.highlightFieldTimer = setTimeout(() => {
        this._highlight()
        this.runOnNextUpdateHook = false
      }, TIMEOUT)
    },
    _highlight(){
      this.$nextTick(() => {
        const realpath = getFirstKeyFromMap(this.control.path)
        highlightField.call(this, {path: realpath})
      })
    }
  }
};
