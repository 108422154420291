<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row class="my-auto mx-0 statistics-box">
          <v-col
            :id="block_id"
            v-click-outside="triggerOnFocus"
            cols="6"
          >
            <div class="mb-4">
              <v-tooltip
                v-for="(btn, idx) in visibleActions"
                :key="idx"
                bottom
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    min-width="36px"
                    max-width="36px"
                    v-bind="attrs"
                    :disabled="btn.loading"
                    :loading="btn.loading"
                    v-on="on"
                    @click="callFunction(btn.action, { action: btn.action })"
                  >
                    <v-icon :color="btn.color || ''">
                      {{ btn.icon }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ btn.name }}</span>
              </v-tooltip>
              <ptd-list />
            </div>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  ref="out_number"
                  v-model="about_declaration.out_number"
                  :error="about_declaration.out_number?.length > 50"
                  maxlength="50"
                  outlined
                  dense
                  hide-details="auto"
                  :background-color="
                    blocked_visibility ? 'grey lighten-2' : 'white'
                  "
                  :disabled="blocked_visibility"
                />
              </v-col>
              <p class="slash">
                /
              </p>
              <v-col>
                <v-text-field
                  ref="remark"
                  v-model="about_declaration.remark"
                  outlined
                  dense
                  hide-details="auto"
                  class="remark"
                  :background-color="
                    blocked_visibility ? 'grey lighten-2' : 'white'
                  "
                  :disabled="blocked_visibility"
                  placeholder="Примечание"
                />
              </v-col>
              <v-col
                cols="3"
                class="pb-0"
              >
                <custom-date-picker
                  ref="declaration_date"
                  v-model="about_declaration.declaration_date"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                  class="pr-1"
                  dense
                  :background-color="
                    blocked_visibility ? 'grey lighten-2' : 'white'
                  "
                  :disabled="blocked_visibility"
                />
              </v-col>
            </v-row>
          </v-col>
          <document-control
            v-if="!pinned.includes('controls')"
            class="ml-2"
            source="rs/getControl"
            pin-name="controls"
          >
            <template slot="action">
              <common-errors-action />
            </template>
          </document-control>
        </v-row>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="showComponent"
      :selected="selected"
      :params="activeComponentParams"
    />
  </v-container>
</template>

<script>
import CustomDatePicker from "@/components/shared/custom-date-picker";
import CommonErrorsAction from "@/components/rs/controls/common-errors-action.vue";
import DocumentControl from "@/components/shared/document-control.vue";
import { mapGetters } from "vuex";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'
import {
  downloadXml,
  getFilenameFromContentDisposition,
  downloadFile,
  validateRequiredParams,
  performDocumentControl,
  getXmlString,
  getSignedDocument,
  sendToEclient,
  SELECTED_REQUIRED, NAMESPACE_REQUIRED, USER_REQUIRED, displayControlResponse, PTO_REQUIRED,
} from "@/helpers/documents";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import ToolboxMixin from "@/mixins/toolbox.mixin";
import {about_declaration as onAboutDeclaration} from "@/events/statistics/control";
import {highlightField} from "@/helpers/control";
import PtdList from "@/components/rs/ptd-list.vue";

export default {
  components: {
    DocumentControl,
    CustomDatePicker,
    CommonErrorsAction,
    PtdList
  },
  mixins: [blockAutoUpdate, ToolboxMixin, blockVisibility],
  data:() => ({
    block_id: "rs-toolbox",
    selectedPto:null,
    namespace:"rs",
    about_declaration: {
      declaration_date: "",
      declaration_id: null,
      out_number: "",
      remark: "",
    },
    actions: [
      {
        visible: true,
        loading: false,
        icon: "mdi-upload",
        action: "sendDocument",
        name: "Отправить в ИСТО",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-pdf-box",
        action: "downloadPdf",
        name: "Скачать PDF",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-document-plus",
        action: "orderPdf",
        name: "Формирование заявки в РС",
      },
      {
        visible: false,
        icon: "",
        action: "showDocumentControlResponse",
        name: "Контроль FLK",
        component: () =>
          import("@/components/shared/document-control-response.vue"),
      },
      {
        visible: false,
        icon: "",
        action: "showXsdControlResponse",
        name: "Контроль XSD",
        component: () =>
          import("@/components/documents/xsd-control-response.vue"),
      },
      {
        visible: false,
        icon: "",
        action: "showDocumentControlPto",
        name: "Контроль ПТО",
        component: () => import("@/components/shared/document-control-pto.vue"),
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-xml-box",
        action: "downloadXml",
        name: "Скачать XML",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-shield-account",
        action: "downloadPtdXml",
        name: "Скачать XML ПТД",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
      pinned: "ui/getPinned",
      user: "auth/getUser",
      requests:"rs/getRequests"
    }),
    elDocIndicatorCondition(){
      return this.selected.type_declaration.el_doc_indicator
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
    },
    showComponent(nv){
      if(nv === false) this.setLoading('sendDocument', false)
    }
  },
  created() {
    onAboutDeclaration.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onAboutDeclaration.unsubscribe();
  },
  methods: {
    checkIndicator(){
      if(this.elDocIndicatorCondition === "ОО"){
        const message = "Документ с типом ОО подавать в НАСТД запрещено."
        this.$error(message)
        throw new Error(message)
      }
    },
    compareFillerCredentials(){
      if(this.elDocIndicatorCondition === "ЭД"){
        const {last_name = "", first_name = ""} = this.selected.filler.filler_json
        return this.$store
          .dispatch("auth/getUserPersonalById", this.user.user.id)
          .catch((err) => {
            this.$error("Не удалось загрузить информацию о пользователе")
            throw err
          })
          .then(({data}) => {
            const { last_name: userLastName = "", first_name: userFirstName = ""} = data
            let fillerLastName = last_name.toLowerCase().trim(),
              fillerFirstName = first_name.toLowerCase().trim(),
              currentLastName = userLastName.toLowerCase().trim(),
              currentFirstName = userFirstName.toLowerCase().trim()
            if(fillerLastName !== currentLastName || fillerFirstName !== currentFirstName){
              const message = "Данные 54 графы (Ф.И) не соответствуют Персональным данным"
              this.$error(message)
              throw new Error(message)
            }
          })
      }
      return Promise.resolve()
    },
    validateRequiredParams,
    performDocumentControl,
    displayControlResponse,
    getXmlString,
    getSignedDocument,
    sendToEclient,
    sendDocument(){
      this.setLoading('sendDocument', true)
      this.validateRequiredParams({
        ...SELECTED_REQUIRED,
        ...NAMESPACE_REQUIRED,
        ...USER_REQUIRED,
        ...PTO_REQUIRED
      })
        .then(this.checkIndicator)
        .then(this.compareFillerCredentials)
        .then(this.performDocumentControl)
        .then(this.displayControlResponse)
        .then(this.showDocumentControlPto)
        .then(this.updateSelectedPto)
        .then(this.getXmlString)
        .then(this.getSignedDocument)
        .then(this.sendToEclient)
        .catch(() => {})
        .finally(() => this.setLoading('sendDocument', false))
    },
    highlightField,
    setFields() {
      const about_declaration = this.selected.about_declaration;

      Object.keys(this.about_declaration).forEach((key) => {
        if (key in about_declaration) {
          this.about_declaration[key] = about_declaration[key];
        }
      });
    },
    downloadPtdXml() {
      this.$store.dispatch("rs/getPtdXml", this.selected.id).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      }).catch(() => this.$info("Документ отсутствует"));
    },
    downloadXml() {
      this.$store.dispatch("rs/getXml", this.selected.id).then((res) => {
        downloadXml(res.data, getFilenameFromContentDisposition(res));
      });
    },
    uploadData() {
      const about_declaration = convertEmptyStringsToNull({
        ...this.about_declaration,
      });

      return this.$store.dispatch("rs/uploadBlockData", {
        name: "about_declaration",
        value: about_declaration,
      });
    },
    downloadPdf() {
      this.$store
        .dispatch("rs/downloadPdf", this.selected.id)
        .then((res) => {
          downloadFile({
            data: res.data,
            type: "application/pdf",
            name: getFilenameFromContentDisposition(res),
          });
        })
        .catch(() => this.$error("Ошибка"));
    },
    orderPdf() {
      this.$store
        .dispatch("rs/orderPdf", this.selected.id)
        .then((res) => {
          downloadFile({
            data: res.data,
            type: "application/pdf",
            name: getFilenameFromContentDisposition(res),
          });
        })
        .catch(() => this.$error("Ошибка"));
    },
  },
};
</script>

<style scoped>
.burger span {
  display: block;
  background: #333333;
  height: 2px;
  width: 100%;
  margin: 10px 0;
}
.slash {
  display: block;
  text-align: center;
  height: 100%;
  margin: auto 0;
}
</style>
