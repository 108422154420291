<template>
  <div>
    <v-container
      fluid
    >
      <v-dialog
        v-model="dialogAccept"
        width="550"
      >
        <v-card>
          <v-toolbar
            color="#333"
            dark
          >
            <div class="text-h5">
              Подтвердить действие
            </div>
          </v-toolbar>
          <div class="pa-6">
            <div class="text-body-1 black--text">
              Удалить все товары?
            </div>
          </div>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#333"
              text
              @click="dialogAccept = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="#5cb7b1"
              dark
            >
              <!-- @click="deleteAllGoods" -->
              Подтвердить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row class="mb-1">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">4 Всего товаров ({{ wares.length }})</span>
          <!-- <v-menu
            offset-x
            v-if="!blocked_visibility"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                max-height="32px"
                min-width="32px"
                max-width="32px"
                text
                tabindex="-1"
                v-bind="attrs"
                v-on="on"
                class="ml-2 elevation-0 wares-menu"
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="sortGoods('id')"
                class="wares-menu-sort-id"
              >
                Сортировать по порядку
              </v-list-item>
              <v-list-item
                @click="sortGoods('code')"
                class="wares-menu-sort-tnved"
              >
                Сортировать по ТНВЭД
              </v-list-item>
              <v-list-item @click="dialogAccept = true">
                Удалить все товары
              </v-list-item>
              <v-list-item
                @click="deleteSeveralGoods"
              >
                Удалить несколько товаров
              </v-list-item>
            </v-list>
          </v-menu>
          <GoodsModal
            :show.sync="waresModal"
            :wares-params="waresParams"
          /> -->
        </v-col>
        <!-- <v-col
          cols="2"
          v-if="!blocked_visibility"
        >
          <DownloadExcel />
        </v-col> -->
        <!-- <v-col
          cols="3"
        >
          <v-btn
            small
            text
            class="py-5"
            tabindex="-1"
            @click="downloadTemplate"
          >
            <v-icon small>
              mdi-download
            </v-icon>
            Скачать спецификацию
          </v-btn>
        </v-col> -->
      </v-row>
      <div class="rs-goods-list">
        <goods-item
          v-for="(item, idx) in wares"
          :key="'goods-items-' + idx"
          ref="goodsItem"
          :item="item"
          :index="idx"
          :nsi-transport-marks="nsiTransportMarks"
          :blockedvis="blocked_visibility"
        />
      </div>

      <v-col
        cols="12"
        class="px-0 d-flex justify-space-between align-end"
      >
        <!-- v-if="!blocked_visibility" -->
        <v-btn
          v-if="!blocked_visibility"
          color="#5CB7B1"
          :disabled="inProcess"
          @click="addGoodsItem"
        >
          <v-icon>mdi-plus</v-icon> Добавить товар
        </v-btn>
        <v-btn
          v-if="!blocked_visibility"
          dark
          :disabled="inProcess"
          @click="validatePayments"
        >
          <v-icon>mdi-currency-eur</v-icon>Рассчитать платежи
        </v-btn>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import GoodsItem from "./goods/goods-item.vue";
import { mapGetters } from "vuex";
import {validatePayments} from './controls/payments'
// import { isGoodsRangeValid, isCorrectStartEnd, rangeToArray } from "@/helpers/inputs";
// import GoodsModal from './goods/goods-modal.vue'

// import DownloadExcel from './goods/download-excel.vue'
import blockVisibility from '@/mixins/block-visibility'
// import {downloadFile, getNameFromDisposition} from '@/helpers/documents'

// import {openGoodsModal} from '@/events/common'
export default {
  components: {
    GoodsItem,
    // GoodsModal,
    // DownloadExcel
  },
  mixins: [blockVisibility],
  data:() => ({
    inProcess:false,
    waresModal: false,
    waresParams: null,
    dialogAccept: false,
    nsiTransportMarks: []

  }),
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      wares: "rs/getSelectedWares",
    }),
  },
  created() {
    this.loadTransportMarks()
  },
  // beforeDestroy(){
  //   openGoodsModal.unsubscribe()
  // },
  methods: {
    validatePayments,
    loadTransportMarks(){
      this.$store.dispatch('catalogs/fetchNsiTransportMarks').then((items) => {
        this.nsiTransportMarks = items
      }).catch(() => {
        this.$snackbar({text: "Ошибка загрузки справочника",color:"red", top:false, right: false});
      })
    },
    // deleteAllGoods(){
    //   this.$store.dispatch("statistics/deleteAllGoods")
    //     .then(() => {
    //       this.$snackbar({text:"Действие применено", top:false, right: false})
    //     })
    //     .catch(() => {
    //       this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
    //     })
    //   this.dialogAccept = false
    // },
    // deleteSeveralGoods(){
    //   this.waresParams = {action: 'delete'}
    //   this.waresModal = true
    // },
    // onOpenModal(params){
    //   this.waresParams = params
    //   this.waresModal = true
    // },
    addGoodsItem() {
      this.inProcess = true
      this.$store.dispatch("rs/addNewGoodsItem").then(() => {
        const tabIndexButtons = document.querySelectorAll('.ware-item-toggle')
        tabIndexButtons[tabIndexButtons.length - 1].focus()
        this.inProcess = false
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      })
    },
    // sortGoods(sortBy) {
    //   this.$store.dispatch("statistics/sortGoods", { sortBy });
    // },
    // downloadTemplate(){
    //   this.$store.dispatch('statistics/loadTemplate').then(res => {
    //     const {data} = res
    //     const name =  getNameFromDisposition(res)
    //     downloadFile({data, name,  type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'})
    //   }).catch(err => {
    //     this.$snackbar({text:err | "Ошибка", color:"red", top:false, right: false})
    //   })
    // }
  },
};
</script>
