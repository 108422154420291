<template>
  <div>
    <!-- v-click-outside="checkOrderAndUpdate" -->
    <v-container
      fluid
    >
      <div
        class="statistics-box position-relative rs-calculation-list"
        :class="{'blocked-box' : blocked_visibility}"
      >
        <v-col cols="12">
          <v-row>
            <v-col
              cols="12"
              class="pb-0 px-4"
            >
              <span class="font-weight-bold">
                B Подробности подсчета
              </span>
            </v-col>
            <v-col
              cols="12"
              class="pb-0 px-4"
            >
              <label>Сумма платежей</label>
              <div>
                <v-chip
                  ref="common"
                  label
                  class="py-5"
                >
                  {{ common_payment_sum_byn }} / {{ getRemainder() }}
                  <span class="ml-6">BYN</span>
                </v-chip>
              </div>
            </v-col>
            <v-col
              cols="12"
            >
              <v-btn
                v-if="!declaration_payments.length"
                class="elevation-0"
                color="transparent"
                @click="addNewPayment"
              >
                Добавить <v-icon>mdi-plus</v-icon>
              </v-btn>
              <div
                v-if="declaration_payments.length"
                class="d-flex px-1"
              >
                <div class="header__code">
                  <small>Код ТП</small>
                </div>
                <div class="header__summ">
                  <small>Сумма ТП</small>
                </div>
                <div
                  class="header__currency"
                >
                  <small>Валюта</small>
                </div>
                <div
                  v-show="selected.type_declaration.customs_procedure === 'ЮЛ'"
                  class="header__summ"
                >
                  <small>УНП</small>
                </div>
                <div
                  v-show="selected.type_declaration.customs_procedure === 'ФЛ' || selected.type_declaration.customs_procedure === '' || selected.type_declaration.customs_procedure === null"
                  class="header__id"
                >
                  <small>Идентификационный номер </small>
                </div>
                <div class="header__id">
                  <small>Номер платежного документа</small>
                </div>
                <div class="header__date">
                  <small>Дата уплаты </small>
                </div>
                <div class="header__code_sp">
                  <small>Код СП</small>
                </div>
              </div>
              <calc-item
                v-for="(item, i) in declaration_payments"
                :key="i"
                :item="item"
                :index="i"
                :payment-types="payment_types"
                :payments-amount="declaration_payments.length"
                :payment-features="payment_features"
                :control="calculationControlPath"
                @getRemainder="getRemainder"
              />
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CalcItem from './calc-item.vue'
import blockVisibility from "@/mixins/block-visibility"
import {getScrollOffset} from '@/helpers/control'
import {declaration_payments as onPayments} from '@/events/statistics/control'

export default {
  components: {
    CalcItem
  },
  mixins: [blockVisibility],
  data() {
    return {
      declaration_payments: [],
      payment_types: [],
      payment_features: [],
      common_payment_sum_byn: null,
      calculationControlPath: {
        path: null
      },
    }
  },
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
      selectedWares: "rs/getSelectedWares",
    })
  },
  watch: {
    selected: {
      handler() {
        this.setFields()
        this.fetchNsiPaymentTypes()
        this.fetchNsiPaymentFeatures()
        this.getRemainder()
      },
      deep: true,
      immediate: false
    },
  },
  created() {
    onPayments.subscribe(this.onControl);
  },
  beforeDestroy() {
    onPayments.unsubscribe();
  },
  methods: {
    onControl(data) {
      const docs = document.querySelector(".rs-calculation-list");
      const offset = getScrollOffset();
      this.$scrollTo(docs, 200, {
        force: true,
        offset,
        onDone: () => this.highlight(data),
      });
    },
    highlight(data) {
      this.calculationControlPath = data
    },
    getRemainder(){
      if(!this.declaration_payments.length) return 0
      const sum = this.declaration_payments.reduce(function(p,c){return parseFloat(p) + parseFloat(c.sum);},0)
      return parseFloat(this.common_payment_sum_byn - sum).toFixed(2)
    },
    addNewPayment(){
      this.inProcess = true
      this.$store.dispatch('rs/createNewPayment').then(() => {
        this.inProcess = false
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      })
    },
    setFields(){
      this.declaration_payments = this.selected?.declaration_payments || []
      this.common_payment_sum_byn = this.selected?.common_payment_sum_byn
    },
    fetchNsiPaymentTypes(){
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then(res => {
        this.payment_types = res.data.list.map((i) => {
          return {
            ...i,
            search: `${i.code}-${i.name}`
          }
        })
      }).catch(() => {
        this.$snackbar({text:'Возникла ошибка при получении типа справочника', color:'red', top:false, right: false})
      })
    },
    fetchNsiPaymentFeatures(){
      this.$store.dispatch('catalogs/fetchNsiPaymentFeatures').then(res => {
        this.payment_features = res.data.list
      }).catch(() => {
        this.$snackbar({text:'Возникла ошибка при получении типа справочника', color:'red', top:false, right: false})
      })
    },
  }
}
</script>

<style scoped>
.header__code{
   width: 100px !important;
   padding: 0 10px 0 0;
}
.header__code_sp{
   width: 120px !important;
   padding: 0 10px 0 0;
}
.header__currency{
    width: 110px !important;
    padding: 0 10px 0 0;
}
.header__summ{
   width: 170px !important;
   padding: 0 10px 0 0;
}
.header__id{
   width: 210px !important;
   padding: 0 10px 0 0;
}
.header__date{
   width: 170px !important;
   padding: 0 10px 0 0;
}

.document-add {
  position: absolute;
  top:50%;
  right: 50%;
  transform: translateX(50%);
  z-index: 1;
}
</style>
