<template>
  <v-btn
    color="#5cb7b1"
    dark
    tabindex="-1"
    @click="perform"
  >
    Контроль
  </v-btn>
</template>
<script>
import { mapGetters } from 'vuex'
import {toggleControlsPanel,toggleControlsNotification} from '@/events/common'

export default {
  computed:{
    ...mapGetters({
      requests:"rs/getRequests"
    })
  },
  methods:{
    async perform(){
      await Promise.all(this.requests)
      const id = this.$route.params.id
      this.$store.dispatch('rs/performControl', id)
        .then((res) => {
          toggleControlsPanel.trigger(true)
          if(!res.data?.length){
            toggleControlsNotification.trigger()
          }
        })
        .catch(err => {
          this.$snackbar({text:err.response || 'Ошибка', color:'red', top:false, right: false})
        })
    },
  }
}
</script>