<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <div
          class="statistics-box d-flex rs-main-info"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-col cols="6">
            <label>
              1 Декларация
              <div class="d-flex">
                <v-text-field
                  :value="type_declaration.declaration_kind_code"
                  outlined
                  hide-details="auto"
                  dense
                  disabled
                  background-color="grey lighten-2"
                  class="mr-1 p-0 "
                />
                <v-autocomplete
                  ref="customs_procedure"
                  v-model="type_declaration.customs_procedure"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="['ЮЛ', 'ФЛ']"
                  class="mr-1 p-0"
                  @change="changeType()"
                />
                <v-autocomplete
                  v-model="type_declaration.el_doc_indicator"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="['ЭД', 'ОО']"
                  class="mr-1 p-0"
                />
                <v-autocomplete
                  v-model="type_declaration.correction_kind"
                  auto-select-first
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  :items="['', 'КС']"
                  class="mr-1 p-0"
                />
              </div>
            </label>
          </v-col>
          <v-col
            cols="4"
          >
            <label>
              Корректируемый документ
              <div class="d-flex">
                <v-text-field
                  ref="correction_reg_num"
                  v-model="type_declaration.correction_reg_num"
                  v-mask="'#####/######/У######'"
                  outlined
                  hide-details="auto"
                  dense
                  class="mr-1 p-0 "
                  :disabled="!isCorrectionRegnumActive"
                  :background-color="isCorrectionRegnumActive ? 'white' : 'grey lighten-2'"
                />
              </div>
            </label>
          </v-col>
          <v-col
            cols="2"
          >
            <label>
              3  Формы
              <div class="d-flex">
                <v-text-field
                  class="mr-1"
                  outlined
                  dense
                  background-color="grey lighten-2"
                  hide-details="auto"
                  disabled
                  :value="additionalPages"
                />
              </div>
            </label>
          </v-col>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="statistics-box d-flex pb-1">
          <v-col
            cols="4"
          >
            <label>
              А Регистрационный номер
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-icon
                    class="mx-1"
                    tabindex="-1"
                    small
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >mdi-tune</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    @click="disabled = !disabled"
                  >Ручное заполнение</v-list-item>
                </v-list>
              </v-menu>
              <v-text-field
                v-model="registration_details.reg_num_a"
                v-mask="'#####/######/У######'"
                :background-color="disabled ? 'grey lighten-2' : 'white'"
                :disabled="disabled"
                outlined
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
          <v-col
            cols="4"
          >
            <label>
              С  Номер выпуска
            </label>
            <v-text-field
              v-model="registration_details.reg_num_b"
              v-mask="'######/#У######'"
              class="mr-1"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              outlined
              dense
              :disabled="disabled"
              hide-details="auto"
            />
          </v-col>
          <v-col
            cols="3"
          >
            <label>
              Дата выпуска
            </label>
            <custom-date-picker
              v-model="registration_details.reg_date_b"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              :disabled="disabled"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import { type_declaration as onTypeDeclaration} from '@/events/statistics/control'
import {highlightField} from '@/helpers/control'
import { eventBus } from "@/bus.js";
import blockVisibility from '@/mixins/block-visibility'

export default {
  components: {CustomDatePicker},
  mixins: [blockAutoUpdate, blockVisibility],
  data() {
    return {
      date: null, // времменая дата
      block_id: 'rs-main-info',
      disabled:true,
      type_declaration: {
        declaration_id: null,
        declaration_kind_code: 'ТТ',
        customs_procedure: '',
        el_doc_indicator: 'ЭД',
        correction_kind: '',
        correction_reg_num: ''
      },
      registration_details: {
        declaration_id: null,
        reg_num_a: '',
        reg_num_b: '',
        reg_date_b: ''
      },
    }
  },
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      selected_wares: "rs/getSelectedWares"
    }),
    isCorrectionRegnumActive() {
      return ["КС", "АН"].includes(this.type_declaration.correction_kind);
    },
    quantity() {
      return this.selected_wares?.length || 0;
    },
    additionalPages() {
      return this.quantity <= 1 ? 1 : Math.ceil((this.quantity - 1) / 3) + 1
    },
  },
  watch: {
    selected() {
      this.setFields();
    },
    "type_declaration.correction_kind"(nv) {
      if (nv === "") {
        this.type_declaration.correction_reg_num = "";
      }
    },
  },
  created(){
    onTypeDeclaration.subscribe(this.highlightField)
  },
  methods: {
    highlightField,
    changeType(){

      if(this.type_declaration.customs_procedure === 'ЮЛ'){
        eventBus.$emit("clearRsDeclarantPhysical");
      }
      if(this.type_declaration.customs_procedure === 'ФЛ'){
        eventBus.$emit("clearRsDeclarant");
      }
      this.$emit('changeType')
    },
    setFields() {
      const { type_declaration } = this.selected || {};
      const { registration_details } = this.selected || {};
      for (let key in type_declaration) {
        if (key in this.type_declaration) {
          this.type_declaration[key] = type_declaration[key];
        }
      }
      for (let key in registration_details) {
        if (key in this.registration_details) {
          this.registration_details[key] = registration_details[key];
        }
      }
      this.setHasChangesFalse();
    },
    async uploadData(){
      const type_declaration = this.convertEmptyStringsToNull(this.type_declaration);
      const registration_details = this.convertEmptyStringsToNull(this.registration_details)

      const typeDeclarationResponse = await this.$store.dispatch("rs/uploadBlockData", {
        name: "type_declaration",
        value: type_declaration,
      });

      await this.$store.dispatch("rs/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });
      this.disabled = true
      return Promise.resolve(typeDeclarationResponse);
    }
  }
}
</script>
