<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col
      cols="12"
    >
      <v-row
        class="statistics-box pb-4"
        :class="{'blocked-box' : blocked_visibility}"
      >
        <v-col
          cols="12"
          class="d-flex align-center "
        >
          <span class="font-weight-bold"> 14 Декларант
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item>
                  Поиск в справочнике
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col
              cols="2"
              class="pr-0"
            >
              <label>
                Страна
                <v-autocomplete
                  ref="country_letter"
                  v-model="declarant_json.country_letter"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                  class="mr-1"
                  :filter="filterBySearchField"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="px-2"
            >
              <label>
                УНП (ИНН И т.п.)
                <v-text-field
                  ref="declarant_unp"
                  v-model="declarant.declarant_unp"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  @change="checkIsForbiddenOrganization"
                />
              </label>
            </v-col>
            <v-col
              cols="6"
              class="pl-0 pr-6"
            >
              <label>
                Наименование организации
                <v-text-field
                  ref="declarant_name"
                  v-model="declarant.declarant_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
            />
            <v-col
              cols="8"
              class="pr-0"
            >
              <label>
                Улица
                <v-text-field
                  v-model="declarant_json.street_house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="px-2"
            >
              <label>
                Дом
                <v-text-field
                  v-model="declarant_json.house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pl-0 pr-6"
            >
              <label>
                Номер пом.
                <v-text-field
                  v-model="declarant_json.room"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          class="pl-6"
        >
          <v-row>
            <v-col
              cols="3"
              class="pr-0"
            >
              <label>
                Индекс
                <v-text-field
                  v-model="declarant_json.postal_index"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="5"
              class="px-2"
            >
              <label>
                Область/Район
                <v-text-field
                  v-model="declarant_json.region"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="pl-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  v-model="declarant_json.city"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <span class="font-weight-bold text-body-2">Документ, подтверждающий включение лица в реестр УЭО</span>
            </v-col>
            <v-col
              cols="4"
              class="pr-0"
            >
              <label>
                Регистрацион. номер УЭО
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item>
                      Поиск в справочнике
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-text-field
                  v-model="declarant_json.aeo_reg_number"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="px-2"
            >
              <label>
                Страна
                <v-autocomplete
                  v-model="declarant_json.aeo_country_letter"
                  auto-select-first
                  :filter="filterBySearchField"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                  class="mr-1"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="pl-0 pr-6"
            />
          </v-row>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
          <span class="font-weight-bold">Филиал
            <v-btn
              min-width="32px"
              text
              @click="show = !show"
            >
              <v-icon> {{ !show ? 'mdi-chevron-down' : 'mdi-chevron-up' }} </v-icon>
            </v-btn>
          </span>
        </v-col>
        <v-col
          v-show="show"
          cols="6"
          class="py-0"
        >
          <v-row>
            <v-col
              cols="2"
              class="pr-0 pb-0"
            >
              <label>
                Страна
                <v-autocomplete
                  v-model="declarant_json.branch_country_letter"
                  auto-select-first
                  :filter="filterBySearchField"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                  :items="countries"
                  item-text="letterCode"
                  item-value="letterCode"
                  class="mr-1"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="px-2 pb-0"
            >
              <label>
                УНП (ИНН И т.п.)
                <v-text-field
                  v-model="declarant.declarant_branch_unp"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="6"
              class="pl-0 pr-6 pb-0"
            >
              <label>
                Наименование организации
                <v-text-field
                  v-model="declarant.declarant_branch_name"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="8"
              class="pr-0"
            >
              <label>
                Улица
                <v-text-field
                  v-model="declarant_json.branch_street_house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="px-2"
            >
              <label>
                Дом
                <v-text-field
                  v-model="declarant_json.branch_house"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="2"
              class="pl-0 pr-6"
            >
              <label>
                Номер пом.
                <v-text-field
                  v-model="declarant_json.branch_room"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-show="show"
          cols="6"
          class="pl-6 py-0"
        >
          <v-row>
            <v-col
              cols="3"
              class="pr-0"
            >
              <label>
                Индекс
                <v-text-field
                  v-model="declarant_json.branch_postal_index"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="5"
              class="px-2"
            >
              <label>
                Область/Район
                <v-text-field
                  v-model="declarant_json.branch_region"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="pl-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  v-model="declarant_json.branch_city"
                  class="mr-1"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {getCountryNameByCode} from "@/helpers/catalogs";
import {highlightField} from '@/helpers/control'
import {declarant as onDeclarant} from '@/events/statistics/control'
import {eventBus} from "@/bus.js";
import blockVisibility from '@/mixins/block-visibility'
import {checkIsForbidden} from "@/helpers/risks";

export default {
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, blockVisibility],
  data: () => ({
    block_id: 'rs-declarant',
    promises: [],
    show: true,
    declarant: {
      declaration_id: null,
      declarant_branch_name: '',
      declarant_branch_unp: '',
      declarant_first_name: '',
      declarant_identity_doc_number: '',
      declarant_last_name: '',
      declarant_middle_name: '',
      declarant_name: '',
      declarant_unp: '',
      declarant_date_birth: '',
      declarant_phone: ''
    },
    declarant_json: {
      country_name: '',
      country_letter: '',
      personal_number: '',
      postal_index: '',
      region: '',
      city: '',
      street_house: '',
      house: '',
      room: '',
      aeo_country_letter: '',
      aeo_country_name: '',
      aeo_reg_number: '',
      branch_city: '',
      branch_country_letter: '',
      branch_country_name: '',
      branch_house: '',
      branch_postal_index: '',
      branch_region: '',
      branch_room: '',
      branch_street_house: '',
      identity_doc_date_off: '',
      identity_doc_date_on: '',
      identity_doc_code: ''
    }
  }),
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      countries: "catalogs/getNsiCountries"
    })
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
  },
  created() {
    onDeclarant.subscribe(this.highlightField)
    eventBus.$on("clearRsDeclarant", () => {
      this.clearFieldsOnTypeChange()
    });
  },
  methods: {
    highlightField,
    checkIsForbiddenOrganization(identity) {
      checkIsForbidden.call(this, {
        identity,
        promises: this.promises,
        dataModel: "declarant.declarant_unp",
      })
    },
    clearFieldsOnTypeChange() {
      const declarant_physical = {
        ...this.declarant,
        declarant_identity_doc_number: null,
        declarant_last_name: null,
        declarant_first_name: null,
        declarant_middle_name: null,
        declarant_date_birth: null,
        declarant_phone: null,
        declarant_unp: null,
        declarant_name: null,
        declarant_branch_unp: null,
        declarant_branch_name: null,
        declarant_json: {
          ...this.declarant_json,
          country_name: null,
          branch_country_name: null,
          aeo_country_name: null,
          identity_doc_code: null,
          identity_doc_name: null,
          identity_doc_date_on: null,
          identity_doc_date_off: null,
          personal_number: null,
          country_letter: null,
          street_house: null,
          house: null,
          room: null,
          postal_index: null,
          region: null,
          city: null,
          aeo_reg_number: null,
          aeo_country_letter: null,
          branch_country_letter: null,
          branch_street_house: null,
          branch_house: null,
          branch_room: null,
          branch_postal_index: null,
          branch_region: null,
          branch_city: null,
        }
      };

      return this.$store.dispatch("rs/uploadBlockData", {
        name: "declarant",
        value: declarant_physical,
      });
    },
    setFields() {
      const declarant = this.selected.declarant
      const declarant_json = this.selected.declarant.declarant_json

      Object.keys(this.declarant).forEach(key => {
        if (key in declarant) {
          this.declarant[key] = declarant[key]
        }
      })

      Object.keys(this.declarant_json).forEach(key => {
        if (key in declarant_json) {
          this.declarant_json[key] = declarant_json[key]
        }
      })
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        const declarant = {
          ...this.declarant,
          declarant_identity_doc_number: null,
          declarant_last_name: null,
          declarant_first_name: null,
          declarant_middle_name: null,
          declarant_date_birth: null,
          declarant_phone: null,
          declarant_json: {
            ...this.declarant_json,
            country_name: getCountryNameByCode(this.countries, this.declarant_json.country_letter),
            branch_country_name: getCountryNameByCode(this.countries, this.declarant_json.branch_country_letter),
            aeo_country_name: getCountryNameByCode(this.countries, this.declarant_json.aeo_country_letter),
            identity_doc_code: null,
            identity_doc_name: null,
            identity_doc_date_on: null,
            identity_doc_date_off: null,
            personal_number: null,
          }
        };
        return this.$store.dispatch("rs/uploadBlockData", {
          name: "declarant",
          value: declarant,
        });
      })
    },
  }
}
</script>
