<template>
  <v-row>
    <v-col cols="12">
      <span class="font-weight-bold mb-4">
        Сведения о товаре
        <v-icon
          dense
          class="mx-1"
        >mdi-tune</v-icon>
      </span>
    </v-col>
    <v-col cols="2">
      <label>
        33 Код товара
        <v-menu offset-x>
          <template #activator="{ on, attrs }">
            <v-icon
              tabindex="-1"
              class="mx-1"
              small
              dense
              v-bind="attrs"
              v-on="on"
            >mdi-tune
            </v-icon>
          </template>
          <v-list>
            <v-list-item @click="showTNVED">
              Поиск в справочнике
            </v-list-item>
          </v-list>
        </v-menu>
      </label>
      <div class="d-flex">
        <v-text-field
          ref="tn_ved"
          v-model="item.tn_ved"
          v-mask="'##########'"
          class="tn_ved mr-2"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="tnvedChanged"
          @keypress.enter="showTNVED"
        />
        <v-btn
          :class="{'teal lighten-2': tab === 0}"
          max-width="36px"
          min-width="36px"
          text
          @click="toggleTreeView"
        >
          <v-icon>
            mdi-file-tree-outline
          </v-icon>
        </v-btn>
        <v-btn
          max-width="36px"
          min-width="36px"
          text
          @click="showCodeInfo(item.tn_ved)"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col cols="2">
      <label>
        Категория ТС
        <v-menu offset-x>
          <template #activator="{ on, attrs }">
            <v-icon
              tabindex="-1"
              class="mx-1"
              small
              dense
              v-bind="attrs"
              v-on="on"
            >mdi-tune
            </v-icon>
          </template>
          <v-list>
            <v-list-item @click="openTransport">
              Поиск в справочнике
            </v-list-item>
          </v-list>
        </v-menu>
      </label>
      <div class="d-flex">
        <v-autocomplete
          ref="transport_category"
          v-model="item.transport_category"
          :items="transport_cat"
          auto-select-first
          outlined
          dense
          hide-details="auto"
          item-text="code"
          item-value="code"
          background-color="white"
          @change="onCategoryChange"
        />
      </div>
    </v-col>
    <v-col cols="2">
      <label>
        Кол-во мест
        <v-tooltip
          max-width="300"
          top
        >
          <template #activator="{ on, attrs }">
            <v-icon
              tabindex="-1"
              class="mx-1"
              small
              dense
              v-bind="attrs"
              v-on="on"
            >mdi-information
            </v-icon>
          </template>
          Количество мест для сидения, помимо места для водителя
        </v-tooltip>
        <div class="d-flex">
          <v-text-field
            ref="sit_place_count"
            v-model="item.sit_place_count"
            outlined
            dense
            hide-details="auto"
            :disabled="isPlaceDisabled"
            :background-color="isPlaceDisabled ? 'grey lighten-2': 'white'"
          />
        </div>
      </label>
    </v-col>
    <v-col
      cols="2"
    >
      <label>
        Грузоподъемность
      </label>
      <div class="d-flex">
        <input-numeric
          ref="weight_capacity"
          v-model="item.weight_capacity"
          :disabled="!isTransportCategoryS9"
          :fraction="2"
          class="mr-2"
          outlined
          dense
          hide-details="auto"
          :background-color="isTransportCategoryS9 ? 'white' : 'grey lighten-2'"
        />
        <v-autocomplete
          ref="weight_capacity_measure_unit_digit"
          v-model="item.weight_capacity_measure_unit_digit"
          :disabled="!isTransportCategoryS9"
          auto-select-first
          item-text="text"
          item-value="code"
          :items="availableMeasureUnits"
          outlined
          dense
          hide-details="auto"
          :background-color="isTransportCategoryS9 ? 'white' : 'grey lighten-2'"
          :filter="filterBySearchField"
        />
      </div>
    </v-col>
    <ware-dynamic-tnved-tabs
      v-model="tab"
      class="py-0"
      :tabs="tabs"
      :code="code"
    />
  </v-row>
</template>

<script>
import {mapGetters} from 'vuex'
import {showCatalog, catalogValueSelected, tnvedCodeSelected, tnvedCodeChanged} from "@/events/statistics";
import cloneDeep from 'lodash.clonedeep'
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import WareDynamicTnvedTabs from "@/components/documents/ware/ware-dynamic-tnved-tabs.vue";
import {showCodeInfo} from "@/helpers/tnved";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {getMeasureUnitShortName} from "@/helpers/catalogs";
import {readyToUpdateInjector} from "@/helpers/injectors";

const S9_VEHICLE_CATEGORY = "S9"
const O4_VEHICLE_CATEGORY = "O4"
const TONNES = "Т"
export default {
  components: {InputNumeric, WareDynamicTnvedTabs},
  mixins: [HighlightFieldWareSector, filterBySearchFieldMixin],
  inject: {
    readyToUpdate: {
      from: readyToUpdateInjector
    }
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      type: Object,
      required: true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    tab: null,
    tabs: [
      {
        title: "Показать структуру",
        id: 1,
        component: () =>
          import("@/components/tnved/structure.vue"),
      },
    ],
    item: {
      id: "",
      tn_ved: "",
      transport_category: "",
      transport_subcategory: "",
      sit_place_count: "",
      weight_capacity: null,
      weight_capacity_measure_unit_digit: null,
      weight_capacity_measure_unit_letter: null
    },
    code: "",
  }),
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
      selected_wares: "rs/getSelectedWares",
      transport_cat: "catalogs/getNsiTransportCategories",
      measure_units: "catalogs/getNsiMeasureUnits",
    }),
    isPlaceDisabled() {
      return [O4_VEHICLE_CATEGORY, S9_VEHICLE_CATEGORY].includes(this.item.transport_category)
    },
    isTransportCategoryS9(){
      return this.item.transport_category === S9_VEHICLE_CATEGORY
    },
    availableMeasureUnits(){
      return this.measure_units.filter(measure => measure.shortDescription === TONNES)
    }
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeSelected.subscribe(this.checkAndUpdateTNVED)
    catalogValueSelected.subscribe(this.setTransport, [
      'transport_category',
    ])
  },
  beforeDestroy() {
    tnvedCodeSelected.unsubscribe()
  },
  methods: {
    onCategoryChange(value) {
      if ([O4_VEHICLE_CATEGORY, S9_VEHICLE_CATEGORY].includes(value)) this.item.sit_place_count = null
      if(value !== S9_VEHICLE_CATEGORY){
        this.clearWeightCapacity()
      }
    },
    clearWeightCapacity(){
      this.item.weight_capacity_measure_unit_digit = null
      this.item.weight_capacity_measure_unit_letter = null
      this.item.weight_capacity = null
    },
    showCodeInfo,
    toggleTreeView() {
      this.tab = this.tab === 0 ? null : 0
    },
    setTransport({id, field, code}) {
      if (id === this.item.id) {
        this.item[field] = code
        this.readyToUpdate()
      }
    },
    openTransport() {
      showCatalog.trigger({
        type: "transport_category",
        search: this.item.transport_category,
        id: this.item.id,
        field: "transport_category",
      });
    },
    checkAndUpdateTNVED({code, id}) { // Событие при выборе кода из справочника
      if (id === this.item.id) {
        this.item.tn_ved = code;
        this.code = code;
        tnvedCodeChanged.trigger({id: this.item.id, code: this.code});
        this.readyToUpdate()
      }
    },
    tnvedChanged() { // Событие при "выходе" из инпута
      this.code = this.item.tn_ved
      tnvedCodeChanged.trigger({id: this.item.id, code: this.code});
    },
    updateEditableGoodsItem() {
      const item = {
        ...this.item,
        weight_capacity_measure_unit_letter: getMeasureUnitShortName(this.measure_units, this.item.weight_capacity_measure_unit_digit)
      }
      this.$emit('update:info', item)
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
    showTNVED() {
      showCatalog.trigger({
        type: "tnved",
        id: this.item.id,
        search: this.item.tn_ved,
      });
    },
    setFields() {
      const ware = cloneDeep(this.selected_wares[this.index])
      Object.keys(this.item).forEach(key => {
        if (key in ware) {
          this.item[key] = ware[key]
        }
      })
      this.code = this.info.tn_ved;
    },
  }
}
</script>
<style scoped>
.show-tnved {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
</style>
