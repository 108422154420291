<template>
  <div
    v-intersect="onIntersect"
    class="transportation-box rs-goods-item"
  >
    <div
      class="transportation-box__header"
      :class="{
        'transportation-box__header_close': !show,
        'transportation-box__header_open': show,
      }"
    >
      <div
        class="d-flex align-center transition-box__params"
        @click.capture="toggle"
      >
        <div>
          <span
            v-if="visible"
            class="font-weight-bold"
          >  {{ info.sort_index }}</span>
        </div>

        <div
          cols="2"
          class="transition-box__param font-weight-bold"
        >
          <span v-if="visible && info.tn_ved">{{ info.tn_ved }} <span class="teal--text">{{ info.add_tn_ved }}</span></span>
        </div>
        <div class="transition-box__param mr-4">
          <p
            v-if="visible"
            class="my-auto"
          >
            {{ title }}
          </p>
        </div>
      </div>
      <div class="d-flex">
        <v-btn
          max-height="32px"
          min-width="32px"
          max-width="32px"
          class="float-right ware-item-toggle"
          @focus.prevent
          @click.prevent="toggle"
        >
          <v-icon>
            {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>

        <v-menu
          v-if="!blockedvis"
          offset-x
        >
          <template #activator="{ on, attrs }">
            <v-btn
              tabindex="-1"
              max-height="32px"
              min-width="32px"
              max-width="32px"
              color="#5CB7B1"
              v-bind="attrs"
              class="ml-2 wares-item-menu"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class="wares-item-menu-delete"
              @click="deleteItem"
            >
              Удалить
            </v-list-item>
            <v-list-item
              class="wares-item-menu-copy"
              @click="copyItem"
            >
              Копировать
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <div
      v-if="render"
      :id="block_id"
      v-click-outside="triggerOnFocus"
      class="editable-content"
    >
      <v-expand-transition>
        <div v-show="show">
          <div
            class="statistics-box pa-4"
            :class="{'blocked-box' : blockedvis}"
          >
            <code-block
              :index="index"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :info="info"
              :control="waresControlPath"
              @update:info="updateInfoInComponent"
            />
            <description
              :index="index"
              :info="info"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :ware-details="wareDetails"
              :show-quantity="showWareDetailQuantity"
              :control="waresControlPath"
              :nsi-transport-marks="nsiTransportMarks"
              @update:info="updateInfoInComponent"
              @releaseChanged="readyToUpdate"
            />
            <payments
              :info="info"
              :index="index"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              :control="waresControlPath"
              @update:info="updateInfoInComponent"
              @updateBenefit="updateBenefit"
            />
          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CodeBlock from "./code.vue";
import Description from "./description.vue";
import Payments from './payments.vue';
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import formatDate from '@/mixins/format-date.js'
import {wares as onWares} from '@/events/statistics/control'

import {
  forceUpdateWareDetail,
  wareDatailForceUpdated,
  wareDetailModified,
  addPromise,
} from "@/events/statistics";
import moment from "moment"
import cloneDeep from 'lodash.clonedeep'
import { convertEmptyStringsToNull } from '../../../helpers/objects';
import {getFirstValueFromMap, removeFirstFromMap} from "@/helpers/control";
import {readyToUpdateInjector} from "@/helpers/injectors";

export default {
  components: {
    CodeBlock,
    Description,
    Payments,
  },
  mixins: [blockAutoUpdate, formatDate],
  provide(){
    return {
      [readyToUpdateInjector]: this.readyToUpdate
    }
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
    nsiTransportMarks: {
      required: true,
      type: Array,
    },
    blockedvis: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    show: false,
    block_id: null,
    is_goods_item: true,
    info: {},
    title: "",
    wareDetails: [],
    render: false,
    isIntersecting: false,
    promises:[],
    promisesWares:[],
    onWares:null,
    waresControlPath:{
      path:"",
      timestamp:null
    },
    addMeasureLetterInput:null,
    availableExcises: {},
  }),
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      measure_units: 'catalogs/getNsiMeasureUnits',
      user:"auth/getUser",
    }),
    customsIndex() {
      const value = (this.info.statistical_cost / this.info.net_weight).toFixed(2)
      if(!isNaN(value) && this.info.statistical_cost && this.info.net_weight){
        return `${value}`
      }
      return "0"
    },
    visible() {
      return this.index < 16 || this.isIntersecting;
    },
    showWareDetailQuantity(){
      return !!(this.info.add_measure_unit_letter || document.activeElement === this.addMeasureLetterInput) || false
    }
  },
  watch: {
    index(){
      this.block_id = `rs-goods-item-${this.index}`
    },
    item: {
      handler(nv) {
        this.info = cloneDeep(nv);
        this.block_id = `rs-goods-item-${this.index}`;
        if (this.visible) {
          this.setTitle();
        }
      },
      immediate: true,
      deep: true,
    },
    // // Начинаем вычислять когда товар попадает в область видимости
    visible: {
      handler(nv) {
        if (nv === true) this.setTitle();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    wareDetailModified.subscribe(this.checkAndSetVisited);
    wareDatailForceUpdated.subscribe(this.changeWareDetailsUpdated);
    addPromise.subscribe(this.onComponentPromise)
    this.onWares = {...onWares} // Делаем локальную копию чтобы можно было менять имя события
    this.onWares.name = `${this.onWares.name}-${this.index + 1}`
    this.onWares.subscribe(this.onControl)
  },
  beforeDestroy() {
    this.onWares.unsubscribe()
    wareDetailModified.unsubscribe();
    wareDatailForceUpdated.unsubscribe();
    addPromise.unsubscribe();
  },
  methods: {
    onControl({path}){
      const index = getFirstValueFromMap(path)
      const selector = ".rs-goods-item:nth-of-type("+index+")"
      const el = document.querySelector(selector)

      this.$scrollTo(el, 200, {
        force: true,
        onDone:() =>{
          removeFirstFromMap(path)
          this.highlight({el, path})
        }
      })
    },
    highlight({el, path}){
      const [wrapper] = el.children
      if(wrapper.classList.contains('transportation-box__header_close')){
        const [toggle] = wrapper.children
        const clickEvent = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false
        });
        toggle.dispatchEvent(clickEvent)
        this.$nextTick(() => {
          this.waresControlPath = {path}
        })
      }else{
        this.waresControlPath = {path}
      }
    },
    // Cохраяем полученные промисы - раздельно товары и блоки
    onComponentPromise({id,promise, type = "block"}){
      if(id === this.info.id){
        type === "ware" ? this.promisesWares.push(promise) : this.promises.push(promise)
      }
    },
    onAddMeasureChanged({value}){ // См. Статистику
      if((!value || value === "") && this.wareDetails.length){
        const updated = this.wareDetails.filter(i =>
          i.add_measure_unit_quantity !== null &&
          i.add_measure_unit_quantity !== "")
          .map(i => {
            return {
              ...i,
              add_measure_unit_quantity:null
            }
          })
        if(!updated.length) return;
        this.$store.dispatch('rs/updateWareDetail', updated)
      }
    },
    getShipmentDate(date){
      return date ? moment(date).format('DD.MM.YYYY') :""
    },
    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    setTitle() {
      let str = this.info?.title || "";
      if (str.length - 1 > 170) {
        str = `${str.slice(0, 170)} ...`;
      }
      this.title = str;
    },
    // Ставит "посещен" и "были изменения" в true
    checkAndSetVisited({ index, action }) {
      if (this.index === index) {
        this.updateWareDetails(action);
        // Разрешаем блоку обновиться
        this.fieldsBeenVisitedByUser();
        this.triggerOnChange();
        if (["add", "delete"].includes(action)) {
          this.updateEventListeners();
        }
      }
    },
    // ПРИ ДОБАВЛЕНИИ ИЛИ УДАЛЕНИИ ВЛОЖЕННОГО ТОВАРА АКТУАЛИЗИРУЕТ ДАННЫЕ
    // updateWareDetails(action) {
    //   if (["add", "delete"].includes(action)) {
    //     this.fetchWareDetails();
    //   }
    // },
    // Открытиые и закрытие детального вида блока
    // Используется nextTick для того, чтобы привязять обработчики и анимацию
    // когда элементы добавлены в DOM , иначе не будет работать
    toggle() {
      if (this.render === true) {
        this.show = false;
        this.destroyEventListeners();
        this.$nextTick(() => {
          this.render = false;
          // this.setAddMeasureField()
        });
      } else {
        // Устанавливем товар как редактируемый (для просмотра документов) см. миксин
        if (this.info.id) {
          this.$store.commit("rs/SET_EDITABLE_WARE", {
            id: this.info.id,
            index: this.index,
          });
        }
        this.render = true;
        this.$nextTick(() => {
          // this.fetchWareDetails();
          this.setEventListeners();
          // this.getAvailableExcises()
          this.show = true;
          // this.setAddMeasureField()
        });
      }
    },
    setAddMeasureField(){ // Если была проставлена единица и значение стерли
      if(this.show === false){
        this.addMeasureLetterInput = null
      }else{
        const params = this.$refs.params.$el
        this.addMeasureLetterInput = params?.querySelector('.add_measure_unit_digit input') || null
      }
    },
    updateInfoInComponent(data) {
      this.info = {
        ...this.info,
        ...data,
      };
    },
    updateBenefit(data){
      this.readyToUpdate()
      this.info = {
        ...this.info,
        ...data,
      }
    },
    saveVehicleModel(wares){
      const {mark_name, model_name} = wares[0]
      const vehicle = {
        id: null,
        division_id: this.user.user.activeDivisionId,
        user_id: this.user.user.id,
        date_update: null,
        mark_name,
        model_name
      }
      this.$store.dispatch('catalogs/saveModel', {
        userId: this.user.user.id,
        vehicle
      })
    },
    uploadData() {
      // Дожидаемся по очереди завершения всех промисов.
      // Затем ждем обновления всех артикулов - обычно один последний
      // Обновляем весь товар
      return Promise.all(this.promises).then(() => {
        this.promises = []
        forceUpdateWareDetail.trigger({ id: this.info.id });
        return Promise.all(this.promisesWares)
      }).then(() => {
        this.promisesWares = []
        const value = convertEmptyStringsToNull(this.info)
        // value.title = this.rewriteTitle(value)
        return this.$store.dispatch("rs/updateGoodsItem", value);
      }).then((updateResponse) => {
        this.saveVehicleModel(updateResponse.data)
        return updateResponse
      })
    },
    deleteItem() {
      const { id, sort_index } = this.info;
      const index = this.index;
      this.$store.dispatch("rs/deleteGoodsItem", {
        id,
        sort_index,
        index,
      }).then(() => {
        this.$snackbar({text:'Товар удален', top:false, right: false})
      }).catch(() => {
        this.$snackbar({text:`Ошибка удаления товара`, top:false, right: false,color:'red'})
      });
    },
    copyItem(){
      const {id} = this.info
      this.$store.dispatch('rs/copyWare', {id}).then(() => {
        this.$snackbar({text:'Товар скопирован', top:false, right: false})
      }).catch((err) => {
        this.$snackbar({text:`Не удалось скопировать товар. ${err.response.data}`, top:false, right: false,color:'red'})
      })
    },
    // Загрузка при открытии
    getAvailableExcises(){
      const tn_ved = this.info.tn_ved
      if(tn_ved){
        const payload = {
          tn_ved,
          date: this.formatDate(this.selected.about_declaration.declaration_date)
        }
        this.$store.dispatch('rs/getAvailableExcise', payload).then((value) =>{
          this.availableExcises = {...value}
        }).catch((e) => {
          if(e.color){
            return this.$snackbar({text:e.message,color:e.color ?? 'red', top:false});
          }
          return this.$snackbar({text:"Ошибка загрузки акцизов",color:"red", top:false});
        })
      }
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    }
  },
};
</script>

<style scoped>
.transportation-box {
  border-radius: 5px;
  margin: 0 0 10px 0;
  cursor: pointer;
}
.transportation-box__header {
  padding: 0 10px;
  align-items: center;
  display: flex;
  color: white;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
.transportation-box__header_open {
  background: #333333;
  border-radius: 6px 6px 0 0;
}
.transportation-box__header_open span {
  color: white;
}
.transportation-box__header_close {
  background: #f2f2f2;
  border-radius: 6px;
}
.transportation-box__header_close span {
  color: #333333;
}
.transition-box__params {
  font-size: 12px;
  color: black;
  width: 100%;
  padding: 10px 0;
}
.transition-box__param {
  transition: all ease-in-out 0.3s;
}
.transportation-box__header_close .transition-box__param {
  visibility: visible;
}

.transportation-box__header_open .transition-box__param {
  visibility: hidden;
}
.transition-box__params div:nth-child(1) {
  flex: 1;
}
.transition-box__params div:nth-child(2) {
  flex: 3;
}

.transition-box__params div:nth-child(3) {
  flex: 22;
}

.transition-box__params div:nth-child(4) {
  flex: 2;
}

.transition-box__params div:nth-child(5) {
  flex: 1;
}

.transition-box__params div:nth-child(6) {
  flex: 2;
}
.transition-box__params div:nth-child(7) {
  flex: 3;
}
.transition-box__params div:nth-child(8) {
  flex: 2;
}
.transition-box__params div:nth-child(9) {
  flex: 1;
}
</style>
