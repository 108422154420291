<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col
      cols="12"
    >
      <v-row
        class="statistics-box pb-4"
        :class="{'blocked-box' : blocked_visibility}"
      >
        <v-col
          cols="12"
          class="d-flex align-center "
        >
          <span class="font-weight-bold"> 54  Место и дата
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item @click="fillerFromDeclarant">
                  Копировать данные из Декларанта
                </v-list-item>
                <v-list-item @click="fillerFromCab">
                  Заполнить из личного кабинета
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
          <!-- <v-btn
            class="ml-4"
            color="#5CB7B1"
            small
            @click="fillerFromDeclarant"
          >
            Копировать данные из Декларанта
          </v-btn> -->
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Фамилия
            <v-text-field
              ref="last_name"
              v-model="filler_json.last_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Имя
            <v-text-field
              ref="first_name"
              v-model="filler_json.first_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Отчество
            <v-text-field
              v-model="filler_json.middle_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="6"
        >
          <label>
            Должность
            <v-text-field
              ref="position"
              v-model="filler_json.position"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="1"
          class="pr-0"
        >
          <label>
            Страна
            <v-autocomplete
              ref="country_letter"
              v-model="filler_json.country_letter"
              auto-select-first
              :filter="filterBySearchField"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="letterCode"
              item-value="letterCode"
            />
          </label>
        </v-col>
        <v-col
          cols="4"
          class="pr-0"
        >
          <label>
            Код вида док.
            <v-autocomplete
              ref="identity_doc_name"
              v-model="filler_json.identity_doc_name"
              :items="identityDocumentsFiltered"
              :filter="filterBySearchField"
              item-text="search"
              item-value="name"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Номер документа
            <v-text-field
              ref="identity_doc_number"
              v-model="filler_json.identity_doc_number"
              v-mask="'SS#######'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              @input="(v) => (filler_json.identity_doc_number = v.toLocaleUpperCase())"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Дата документа
            <custom-date-picker
              ref="identity_doc_d_on"
              v-model="filler_json.identity_doc_d_on"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              dense
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Дата истечения срока
            <custom-date-picker
              ref="identity_doc_d_off"
              v-model="filler_json.identity_doc_d_off"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              dense
            />
          </label>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
          <span class="font-weight-bold text-body-2">Документ, удостоверяющий полномочия </span>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Номер документа
            <v-text-field
              v-model="filler_json.auth_doc_number"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="3"
          class="pr-1"
        >
          <div class="d-flex justify-space-between align-center">
            <div>
              <label>
                Дата выдачи
                <custom-date-picker
                  v-model="filler_json.auth_doc_d_on"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                  class="pr-1"
                  dense
                />
              </label>
            </div>
            <div>
              <label>
                Дата истечения срока
                <custom-date-picker
                  ref="auth_doc_d_off"
                  v-model="filler_json.auth_doc_d_off"
                  :visited.sync="fields_been_visited"
                  :has-changes.sync="hasChanges"
                  class="pr-1"
                  dense
                />
              </label>
            </div>
          </div>
        </v-col>
        <!-- <v-col
          cols="2"
          offset="3"
          class="pr-0"
        >
          <label>
            Код и наименование вида связи
            <v-autocomplete
              auto-select-first
              return-object
              background-color="white"
              dense
              outlined
              hide-details="true"
              item-text="text"
              item-value="code"
              :items="contact_types"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
        >
          <label>
            Номер
            <v-text-field
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col> -->
        <v-col
          v-if="!contacts.length"
          class="mt-6 text-right"
          cols="4"
          offset="3"
        >
          <v-btn
            dense
            class="elevation-0"
            @click="addContactItem"
          >
            <v-icon>mdi-plus</v-icon> Контакт
          </v-btn>
        </v-col>
        <v-col
          cols="4"
          offset="3"
        >
          <single-contact
            v-for="(contact, index) in contacts"
            :ref="'cont_' + index"
            :key="index"
            :types="contact_types"
            :item="contact"
            :idx="index"
            :contacts-length="contacts.length"
            @deleteContactItem="deleteContactItem"
            @addContactItem="addContactItem"
            @contactsUpdater="contactsUpdater"
          />
          <!--  -->
        </v-col>


        <v-col
          cols="12"
          class="pb-0"
        >
          <span class="font-weight-bold text-body-2">Таможенное агентство
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  class="mx-1"
                  tabindex="-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item>
                  Поиск в справочнике
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
        <v-col
          cols="2"
          class="pr-0"
        >
          <label>
            Реестр
            <v-text-field
              ref="customs_agent_license_num"
              v-model="filler_json.customs_agent_license_num"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
            <!-- <v-autocomplete
              auto-select-first
              v-model="filler_json.customs_agent_license_num"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :items="countries"
              item-text="letterCode"
              item-value="letterCode"
              class="mr-1"
            /> -->
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-1"
        >
          <label>
            Договор
            <v-text-field
              ref="customs_agent_contract_num"
              v-model="filler_json.customs_agent_contract_num"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pr-1"
        >
          <label>
            Дата договора
            <custom-date-picker
              ref="customs_agent_contract_date"
              v-model="filler_json.customs_agent_contract_date"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
              class="pr-1"
              dense
            />
          </label>
        </v-col>
        <v-col
          cols="3"
          offset="3"
        >
          <label>
            Аттестат таможенного представителя
            <v-text-field
              ref="certificate_number"
              v-model="filler_json.certificate_number"
              class="mr-1"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import CustomDatePicker from '@/components/shared/custom-date-picker.vue'
import SingleContact from '@/components/rs/single-contact.vue'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { getCountryNameByCode, getIdentityCodeByName } from "@/helpers/catalogs";
import { highlightField } from '@/helpers/control'
import { filler as onFiller } from '@/events/statistics/control'
import blockVisibility from '@/mixins/block-visibility'

export default {
  components: {
    CustomDatePicker,
    SingleContact
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, blockVisibility],
  data:() => ({
    block_id: 'rs-place-date',
    filler: {
      declaration_id: null,
    },
    filler_json: {
      auth_doc_d_off: '',
      auth_doc_d_on: '',
      auth_doc_number: '',
      certificate_number: '',
      country_letter: '',
      country_name: '',
      customs_agent_contract_date: '',
      customs_agent_contract_num: '',
      customs_agent_license_num: '',
      first_name: '',
      identity_doc_name: '',
      identity_doc_code: '',
      identity_doc_d_off: '',
      identity_doc_d_on: '',
      identity_doc_number: '',
      last_name: '',
      middle_name: '',
      position: ''
    },
    contacts: [],
  }),
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      identityDocuments: "catalogs/getNsiIdentityDocuments",
      countries: "catalogs/getNsiCountries",
      contact_types: "catalogs/getContactTypes"
    }),
    identityDocumentsFiltered(){
      if(this.filler_json.country_letter){
        if( ['BY', 'RU', 'KZ', 'KG', 'AM'].includes(this.filler_json.country_letter)){
          return this.identityDocuments.filter(item => {
            return item.code.startsWith(this.filler_json.country_letter)
          })
        }
        return this.identityDocuments.filter(item => {
          return item.code.startsWith('XX')
        })
      }
      return this.identityDocuments
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
  },
  created(){
    onFiller.subscribe(this.highlightField)
  },
  methods:{
    highlightField,
    fillerFromCab(){
      this.$store.dispatch('rs/fillerFromCab', this.filler.declaration_id).then((res)=>{
        if(res === 200){
          this.$snackbar({text:'Заполнено из личного кабинета', color:'green',top:false})
        }
      })
    },
    fillerFromDeclarant(){
      this.$store.dispatch('rs/fillerFromDeclarant', this.filler.declaration_id).then((res)=>{
        if(res === 200){
          this.$snackbar({text:'Данные из декларанта скопированны', color:'green',top:false})
        }
      })
    },
    setFields() {
      const filler = this.selected.filler
      const filler_json = this.selected.filler.filler_json
      const contacts = filler_json?.contacts ?? []
      this.contacts = cloneDeep(contacts)

      Object.keys(this.filler).forEach(key => {
        if(key in filler){
          this.filler[key] = filler[key]
        }
      })

      Object.keys(this.filler_json).forEach(key => {
        if(key in filler_json){
          this.filler_json[key] = filler_json[key]
        }
      })

    },
    uploadData(){
      const filler = this.convertEmptyStringsToNull({
        ...this.filler,
        filler_json: {
          ...this.filler_json,
          country_name: getCountryNameByCode(this.countries, this.filler_json.country_letter) || null,
          contacts: this.getContactsWithName(),
          identity_doc_code: getIdentityCodeByName(this.identityDocuments, this.filler_json.identity_doc_name) || null
        }
      })
      return this.$store.dispatch("rs/uploadBlockData", {
        name: "filler",
        value: filler,
      });
    },
    getContactsWithName(){
      return this.contacts.map(item => {
        const {name} = this.contact_types.find(i => i.code === item.code) ?? {}
        return {
          ...item,
          name,
        }
      })
    },
    deleteContactItem(index){
      this.contacts.splice(index, 1)
      this.readyToUpdate()
    },
    addContactItem(){
      this.contacts.push({
        code: '',
        name: '',
        number: ''
      })
      this.readyToUpdate()
    },
    contactsUpdater(payload){
      this.contacts[payload.index] = payload.cont_item
      this.readyToUpdate()
    },
    readyToUpdate(){
      this.hasChanges = true,
      this.fields_been_visited = true
    }
  }
}
</script>
