

export default {
  watch: {
    selected:{
      handler(){
        if(this.selected.registration_details.reg_num_a === null){
          this.blocked_visibility = false
        }else if(this.selected.registration_details.reg_num_a === ''){
          this.blocked_visibility = false
        }else{
          this.blocked_visibility = true
        }
      },
      deep:true
    },
  },
  data(){
    return{
      blocked_visibility: false
    }
  }
}

