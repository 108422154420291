<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="d-flex px-1 py-1"
  >
    <div class="header__code">
      <v-autocomplete
        ref="code"
        v-model="payment.code"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        class="mr-1"
        item-text="search"
        item-value="code"
        :items="paymentTypes"
      />
    </div>
    <div class="header__summ">
      <v-text-field
        ref="sum"
        v-model="payment.sum"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @input="(v) => (payment.sum = v.replace(',', '.'))"
      />
    </div>
    <div
      class="header__currency"
    >
      <v-autocomplete
        ref="currency_letter"
        v-model="payment.currency_letter"
        auto-select-first
        :items="currencies"
        item-text="letterCode"
        item-value="letterCode"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :filter="filterBySearchField"
      />
    </div>
    <div
      v-show="selected.type_declaration.customs_procedure === 'ЮЛ'"
      class="header__summ"
    >
      <v-text-field
        ref="declarant_unp"
        v-model="payment.declarant_unp"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div
      v-show="selected.type_declaration.customs_procedure === 'ФЛ' || selected.type_declaration.customs_procedure === '' || selected.type_declaration.customs_procedure === null"
      class="header__id"
    >
      <v-text-field
        ref="declarant_personal_number"
        v-model="payment.declarant_personal_number"
        v-mask="'#######S###SS#'"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div class="header__id">
      <v-text-field
        ref="payment_doc_number"
        v-model="payment.payment_doc_number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>
    <div class="header__date">
      <custom-date-picker
        ref="payment_date"
        v-model="payment.payment_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </div>
    <div class="header__code_sp">
      <v-autocomplete
        ref="payment_type"
        v-model="payment.payment_type"
        auto-select-first
        item-text="search"
        item-value="code"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="nsiPaymentModes"
        :filter="filterBySearchField"
      />
    </div>
    <div>
      <v-btn
        tabindex="-1"
        max-width="36px"
        min-width="36px"
        color="transparent"
        class="elevation-0 document-item-delete"
        :disabled="inProcess"
        @click="deletePayment"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="index == paymentsAmount - 1"
        class="document-add elevation-0"
        color="transparent"
        min-width="36px"
        max-width="36px"
        :disabled="inProcess"
        @click="addNewPayment"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import CustomDatePicker from "@/components/shared/custom-date-picker.vue"
import { mapGetters } from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { convertEmptyStringsToNull } from "@/helpers/objects"
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";

export default {
  components: {
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    paymentTypes: {
      required: true,
      type: Array,
    },
    paymentFeatures: {
      required: true,
      type: Array,
    },
    paymentsAmount: {
      required: true,
      type: Number,
    }
  },
  data(){
    return {
      block_id: null,
      inProcess: false,
      payment: {
        id: null,
        declaration_id: null,
        code: "",
        sum: "",
        currency_letter: "",
        declarant_unp: "",
        declarant_personal_number: "",
        payment_doc_number: "",
        payment_date: "",
        payment_type: "",
        sort_index: "",
      },
    }
  },
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      currencies: "catalogs/getNsiCurrencies",
      customsPoints: "catalogs/getNsiCustomsPoints",
      nsiPaymentModes: "catalogs/getNsiPaymentModes",
    })
  },
  watch: {
    item: {
      handler(newVal) {
        this.block_id = `rs-declaration-payment-${this.index + 1}`;
        for (let key in newVal) {
          if (key in this.payment) {
            this.payment[key] = newVal[key];
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addNewPayment(){
      this.inProcess = true
      this.$store.dispatch('rs/createNewPayment').then(() => {
        this.inProcess = false
        this.uploadData()
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      })
    },
    deletePayment(){
      this.$store.dispatch('rs/deletePaymentItem', this.payment.id)
      this.$emit('getRemainder')
    },
    uploadData(){
      const index = this.index;
      const payload = convertEmptyStringsToNull({
        ...this.payment,
      });

      return this.$store.dispatch('rs/updateDeclarationPayment', {
        index,
        payload,
      })
    }
  }
}
</script>

<style scoped>
.header__code{
   width: 100px !important;
   padding: 0 10px 0 0;
}
.header__code_sp{
   width: 120px !important;
   padding: 0 10px 0 0;
}
.header__currency{
    width: 110px !important;
    padding: 0 10px 0 0;
}
.header__summ{
   width: 170px !important;
   padding: 0 10px 0 0;
}
.header__id{
   width: 210px !important;
   padding: 0 10px 0 0;
}
.header__date{
   width: 170px !important;
   padding: 0 10px 0 0;
}
</style>
