<template>
  <v-row>
    <v-col cols="1">
      <v-checkbox
        v-model="item.benefit"
        tabindex="-1"
        dense
        color="black"
        class="mt-6"
        label="Наличие льготы"
        @change="updateBenefit"
      />
    </v-col>
    <v-col cols="3">
      <label> Фактурная стоимость и валюта </label>
      <div class="d-flex">
        <v-text-field
          v-model="item.invoice_cost"
          class="mr-2"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onInvoiceCostChange"
          @input="(v) => (item.invoice_cost = v.replace(',', '.'))"
        />
        <v-autocomplete
          v-model="item.invoice_currency_letter"
          style="width: 30%"
          auto-select-first
          :items="currencies"
          item-text="letterCode"
          item-value="letterCode"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :filter="filterBySearchField"
          @change="updateCustomsCost"
        />
      </div>
    </v-col>
    <v-col cols="3">
      <label> Таможенная стоимость и валюта </label>
      <div class="d-flex">
        <v-text-field
          v-model="item.customs_cost"
          class="mr-2"
          maxlength="20"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="onCustomsCostChange"
          @input="(v) => (item.customs_cost = v.replace(',', '.'))"
        />
        <v-text-field
          value="BYN"
          readonly
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </div>
    </v-col>
    <v-col cols="5">
      <div>
        <v-sheet
          class="ware_payment_json"
          rounded
        >
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Вид
                  </th>
                  <th class="text-left">
                    Основа начисления
                  </th>
                  <th class="text-left">
                    Ставка
                  </th>
                  <th class="text-left">
                    Сумма
                  </th>
                  <th class="text-left">
                    СП
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(i, idx) in item.ware_payment_json"
                  :key="idx"
                >
                  <td>{{ i.code }}</td>
                  <td>{{ i.basis }}</td>
                  <td>
                    <input-numeric
                      v-model="i.rate"
                      :suffix="i.rate_type"
                      fraction="2"
                    />
                  </td>
                  <td>
                    <input-numeric
                      v-model="i.sum"
                      :suffix="i.currency_letter"
                      fraction="2"
                    />
                  </td>
                  <td>{{ i.payment_feature }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
        <!-- <pre>{{ item }}</pre> -->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
// import { exciseChanged, addPromise, tnvedCodeChanged, updateGoodParameters } from '@/events/statistics'
import { getCustomsCost, normalizeFloat } from "@/helpers/math";
import cloneDeep from "lodash.clonedeep";
import formatDate from "@/mixins/format-date.js";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: { InputNumeric},
  mixins: [filterBySearchFieldMixin, formatDate, HighlightFieldWareSector],
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      type: Object,
      required: true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    item: {
      id: null,
      benefit: "",
      invoice_cost: "",
      invoice_currency_letter: "",
      ware_payment_json: [],
      customs_cost: "",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
      selected_wares: "rs/getSelectedWares",
      currencies: "catalogs/getNsiCurrencies",
    }),
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    this.updateCustomsCost();
  },
  methods: {
    onInvoiceCostChange(value) {
      this.item.invoice_cost = normalizeFloat({ value, afterPoint: 2 });
      this.updateCustomsCost();
    },
    onCustomsCostChange(value) {
      this.item.customs_cost = normalizeFloat({ value, afterPoint: 2 });
    },
    updateCustomsCost() {
      new Promise((resolve, reject) => {
        getCustomsCost
          .call(this, {
            date: this.selected.about_declaration.declaration_date,
            code: this.item.invoice_currency_letter,
            cost: this.item.invoice_cost,
          })
          .then((res) => {
            this.item.customs_cost = res;
            return resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    setFields() {
      const ware = cloneDeep(this.selected_wares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    updateEditableGoodsItem() {
      const payload = {
        ...this.item,
      };
      this.$emit("update:info", payload);
    },
    updateBenefit() {
      const payload = {
        ...this.item,
      };
      this.$emit("updateBenefit", payload);
    },
  },
  //   created(){
  //     tnvedCodeChanged.subscribe(this.onNewCode);
  //     updateGoodParameters.subscribe(this.onParamsChange)
  //   },
  //   beforeDestroy(){
  //     tnvedCodeChanged.unsubscribe();
  //     updateGoodParameters.unsubscribe()
  //   },
  // async onParamsChange({id, payload}){
  //   if(id === this.item.id){
  //     const {invoice_cost} = payload
  //     this.item.invoice_cost = invoice_cost
  //     await this.updateCustomsCost()
  //     this.readyToUpdate()
  //   }
  // },
  // onNewCode({id, code}){
  //   if(id === this.item.id){
  //     this.updateAvailableExcises(code)
  //   }
  // },
  // // получаем справочник по акцизам
  // updateAvailableExcises(code){
  //   // Важно! Принимаем код для того чтобы не делать settimeout
  //   // Компонент Сode эмитит сообщение раньше чем вызовется  watch -> updateInfoInComponent
  //   // Поэтому как альтернатива передается актуальный код ТНВЭД
  //   const tn_ved = code ?? this.info.tn_ved
  //   if(tn_ved){
  //     const promise = new Promise((resolve, reject) => {
  //       const payload = {
  //         tn_ved,
  //         date: this.formatDate(this.selected.about_declaration.declaration_date)
  //       }
  //       this.$store.dispatch('transit/getAvailableExcise', payload).then((value) =>{

  //         this.$emit('update:available-excises', value)

  //         if(value?.data?.length){
  //           this.updateExcise(value)
  //           this.updateExciseMeasure(value)
  //         }else{
  //           this.clearExcise()
  //         }

  //         resolve(value)
  //       }).catch((e) => {
  //         if(e.color){
  //           this.$snackbar({text:e.message,color:e.color ?? 'red', top:false});
  //           reject(e)
  //         }
  //         this.$snackbar({text:"Ошибка загрузки акцизов",color:"red", top:false});
  //         reject(e)
  //       })
  //     })
  //     addPromise.trigger({id:this.item.id, promise})
  //   }else{
  //     this.clearExcise()
  //   }
  // },
  // clearExcise(){
  //   this.$emit('update:available-excises', {})
  //   const data = {
  //     description: null,
  //     excise_id: null,
  //     excise_need: null,
  //     rate_description: null,
  //     transit_excise_id:null
  //   }
  //   this.item.excise_json = data
  //   this.readyToUpdate()
  // },
  // updateExciseMeasure(payload){
  //   const {data} = payload
  //   const [value] = data
  //   const {measureUnitDigit} = value
  //   exciseChanged.trigger({id: this.info.id, measureUnitDigit})
  // },
  // updateExcise(payload){
  //   const {data} = payload
  //   const [value] = data
  //   const excise_json = {
  //     description: value.description,
  //     excise_id: value.tarifId,
  //     excise_need: this.getExciseNeed(value),
  //     rate_description: value.rateDescription,
  //     transit_excise_id: value.id
  //   }
  //   this.item.excise_json = excise_json
  // },
  // onInvoiceCostChange(value){
  //   this.replaceFloatNumbers({value, prop:'invoice_cost', afterPoint:2}) // Нормализуем значение
  //   this.updateCustomsCost() // Обновляем стат стоимость
  // },
  // updateCustomsCost() {
  //   const promise = new Promise((resolve, reject) => {
  //     const date = this.selected.about_declaration.declaration_date
  //     getCustomsCost.call(this, {
  //       date,
  //       code: this.item.invoice_currency_letter,
  //       includingCost: this.item.invoice_cost,
  //     }).then(res => {
  //       this.item.customs_cost = res
  //       return resolve(res)
  //     }).catch(err => reject(err));
  //   })
  //   addPromise.trigger({id:this.info.id, promise})
  //   return promise
  // },
  // replaceFloatNumbers({value, prop, afterPoint = null}){
  //   this.item[prop] = normalizeFloat({value,afterPoint})
  // },
  // clearPayments(){
  //   this.item.ware_payment_json = []
  //   this.readyToUpdate()
  // },
  // updateEditableGoodsItem() {
  //   this.readyToUpdate()
  //   this.$emit('update:info', this.item)
  // },
  // getExciseNeed(value){
  //   return 'tarifId' in value ? (value.tarifId === null ? false : true): (value.id === null ? false : true)
  // },
  // selectOption(value){
  //   this.item.excise_json = {
  //     description: value.description,
  //     excise_id: value.tarifId,
  //     excise_need: this.getExciseNeed(value),
  //     rate_description: value.rateDescription,
  //     transit_excise_id: value.id
  //   }
  //   this.readyToUpdate()
  //   exciseChanged.trigger({id:this.info.id, measureUnitDigit:value.measureUnitDigit})
  // },
  // readyToUpdate(){
  //   this.$emit('update:visited', true)
  //   this.$emit('update:has-changes', true)
  // }
  // }
};
</script>
