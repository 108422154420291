<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="width: 1440px"
  >
    <pinned-template
      :tabs="tabs"
    />
    <rs-toolbox />
    <rs-main-info
      :declarant-type="declarant_type"
      @changeType="changeType"
    />
    <rs-declarant v-show="declarant_type === 'ЮЛ'" />
    <rs-declarant-physical v-show="declarant_type === 'ФЛ' || declarant_type === '' || declarant_type === null" />
    <rs-place-date />
    <documents />
    <goods-character />
    <rs-goods />
    <calculation />
    <catalogs />
    <shared-buffer />
    <distinct-division-notification />
    <forbidden-carrier-notification />
  </section>
</template>

<script>
import PinnedTemplate from '@/components/shared/pinned-template.vue'
import RsToolbox from '@/components/rs/rs-toolbox.vue'
import RsMainInfo from '@/components/rs/rs-main-info.vue'
import RsDeclarant from '@/components/rs/rs-declarant.vue'
import RsDeclarantPhysical from '@/components/rs/rs-declarant-physical.vue'
import RsPlaceDate from '@/components/rs/rs-place-date.vue'
import Documents from '@/components/rs/documents/documents.vue'
import Calculation from '@/components/rs/calc/calculation.vue'
import RsGoods from '@/components/rs/rs-goods.vue'
import GoodsCharacter from '@/components/rs/goods-character.vue'
import {mapGetters} from 'vuex'
import Catalogs from "@/components/catalogs/modals/resolver.vue"
import offsetMixin from '@/mixins/offset.mixin.js'
import SharedBuffer from "@/components/shared/shared-buffer.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import {setDeclarationTitle} from "@/helpers/text";
import ForbiddenCarrierNotification from "@/components/documents/warnings/forbidden-carrier-notification.vue";

export default {
  components: {
    ForbiddenCarrierNotification,
    DistinctDivisionNotification,
    SharedBuffer,
    PinnedTemplate,
    RsToolbox,
    RsMainInfo,
    RsDeclarant,
    RsDeclarantPhysical,
    RsPlaceDate,
    Documents,
    GoodsCharacter,
    Calculation,
    RsGoods,
    Catalogs
  },
  mixins: [offsetMixin],
  provide: {
    rootNamespace: 'rs'
  },
  data: () => ({
    declarant_type: 'ЮЛ',
    tabs: [
      {
        name: 'controls',
        component: () => import('@/components/rs/controls/common-errors.vue')
      },
      {
        name: 'payment-errors',
        component: () => import('@/components/rs/controls/payment-errors.vue'),
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "rs/getSelected",
    }),
  },
  watch: {
    selected: {
      handler(rsDeclaration) {
        const {remark, out_number: outNumber} = rsDeclaration?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix: "РС"
        })
        this.changeType();
      },
      deep: true
    }
  },
  created() {
    this.$store.dispatch("catalogs/fetchAll")
    const id = this.$route.params.id
    this.$store.dispatch("rs/getDocumentById", id).catch(err => {
      if (err.response.status === 403) {
        this.$router.push('/403')
      }
    });
  },
  methods: {
    changeType() {
      if (this.selected.type_declaration) {
        this.declarant_type = this.selected.type_declaration.customs_procedure
      }
    }
  }
}
</script>
