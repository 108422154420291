<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="12">
        <div
          class="statistics-box pa-4 rs-about-wares"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-row>
                <v-col
                  cols="3"
                >
                  <label>
                    15а Страна отправления
                    <div class="d-flex justify-space-between">
                      <v-autocomplete
                        ref="dispatch_country_letter"
                        v-model="about_wares.dispatch_country_letter"
                        style="width:80px;"
                        auto-select-first
                        :filter="filterBySearchField"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :items="countries"
                        item-text="letterCode"
                        item-value="letterCode"
                        class="mr-1"
                        @change="setCountryName"
                      />
                      <v-text-field
                        v-model="about_wares.dispatch_country_name"
                        class="mr-1"
                        outlined
                        dense
                        hide-details="auto"
                        disabled
                        background-color="white"
                      />
                    </div>
                  </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs"
import blockAutoUpdate from "@/mixins/block-auto-update.mixin"
import { getCountryNameByCode } from "@/helpers/catalogs"
import {about_wares as onAboutWares} from '@/events/statistics/control'
// import {getWaresParamTotal} from '@/helpers/math'
import {highlightField} from '@/helpers/control'
import blockVisibility from "@/mixins/block-visibility"

export default {
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, blockVisibility],
  data:() => ({
    // pinName:'goods-character',
    block_id: 'rs-goods-character',
    about_wares: {
      declaration_id: null,
      dispatch_country_letter: '',
      dispatch_country_name: ''
    },
  }),
  computed: {
    ...mapGetters({
      selected: 'rs/getSelected',
      currencies: "catalogs/getNsiCurrencies",
      countries: "catalogs/getNsiCountries",
    //   pinned:'ui/getPinned',
    }),
  },
  watch: {
    selected:{
      handler(){
        this.setFields();
      },
      deep:true,
    }
  },
  created(){
    onAboutWares.subscribe(this.highlightField)
  },
  methods: {
    highlightField,
    setCountryName() {
      this.about_wares.dispatch_country_name = getCountryNameByCode(this.countries, this.about_wares.dispatch_country_letter) || null
    },
    setFields() {
      const about_wares = this.selected.about_wares
      about_wares.dispatch_country_name = getCountryNameByCode(this.countries, about_wares.dispatch_country_letter) || null

      Object.keys(this.about_wares).forEach(key => {
        if(key in about_wares){
          this.about_wares[key] = about_wares[key]
        }
      })
    },
    uploadData(){
      const about_wares = {
        ...this.about_wares,
        dispatch_country_name: getCountryNameByCode(this.countries, this.about_wares.dispatch_country_letter) || null
      };

      return this.$store.dispatch("rs/uploadBlockData", {
        name: "about_wares",
        value: about_wares,
      });
    },
    // togglePin(){
    //   this.$store.commit('ui/TOGGLE_PIN', this.pinName)
    // },
    // recalculateCustomsCost(){
    //   const requests = this.requests
    //   Promise.all(requests)
    //     .then(() => {
    //       return this.$store.dispatch('transit/recalculateCustomsCost', this.selected.id)
    //     }).catch(() => {
    //       this.$snackbar({text:'Ошибка', color:'red', top:false})
    //     })
    // },
  }
}
</script>

<style scoped>
table,
tbody,
tr,
th,
td {
  background-color: #ededed !important;
}
th {
  border-bottom: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}
td {
  border-bottom: transparent !important;
  padding: 0 10px 10px 0 !important;
}
table tr:last-child td {
  padding: 0 10px 4px 0 !important;
}
.v-data-table__wrapper {
  padding-bottom: 0 !important;
}
.position-relative {
  position: relative;
}
.document-add {
  position: absolute;
  bottom: 7%;
  right: 24%;
}
.root-width {
  width: 1440px;
}
.pinned {
  position: fixed;
  top: 0;
  right: 50%;
  width: 100vw;
  transform: translateX(50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}
</style>
