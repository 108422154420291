import store from "@/store"
import router from "@/router"


function _toggle(){
  if(!store.getters['ui/getPinned'].includes("payment-errors")){
    store.commit('ui/TOGGLE_PIN', "payment-errors")
  }
  // alert('выводим ошибки')
}

function _error(text){
  this.$snackbar({text, color:'red', top:false})
}

function _calculate(){
  const id = router.currentRoute.params.id
  store.dispatch('rs/calculatePayments')
    .then(() => {
      store.dispatch("rs/getDocumentById", id)
    })
    .catch(() => _error('Ошибка при расчете платежа"'))
}

function validatePayments(){
  const requests = store.getters['rs/getRequests']
  Promise.all(requests).then(() => {
    const id = router.currentRoute.params.id
    return store.dispatch('rs/validatePayments', id)
  })
    .then(res => {
      res.data && !Object.keys(res.data).length
        ? _calculate()
        : _toggle()          
    })
    .catch(() => _error('Ошибка при валидации документа'))
}

export {validatePayments}