import {getFirstKeyFromMap, getFirstValueFromMap, highlightField, removeFirstFromMap} from "@/helpers/control";

export default {
  props: {
    control: {
      required: true,
      type:Object
    },
  },
  watch: {
    control: {
      handler({path}) {
        if (getFirstValueFromMap(path) - 1 === this.index) {
          this.$nextTick(() => {
            removeFirstFromMap(path)
            const realpath = getFirstKeyFromMap(path)
            highlightField.call(this, {path: realpath})
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
